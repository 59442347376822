@use "./Mixins" as m;

.boxShader {
  background-color: var(--BaseSiteColor2);
  background-image: var(--Gradient2);
  border-radius: 1rem;
}

:focus {
  outline: none;
}

.foragerButton {
  @include m.flexCenter(row);
  width: fit-content;
  height: 100%;
  padding: 0.5rem;
  border-radius: 1rem;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);
  color: var(--SiteFontColor2);
  font-size: 1.25rem;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    &:active {
      background: var(--GradientDarkenReverse1);
      background-color: var(--BaseSiteColor1);
    }

    &.hoverColor {
      &.red {
        background-color: var(--BaseSiteColor5);
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.smallText {
    font-size: 1.1rem;
  }

  &.tinyText {
    font-size: 0.9375rem;
    font-weight: 500;
  }

  &.lessVerticalPadding {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  &.noVerticalPadding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.lessHorizontalPadding {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  &.noHorizontalPadding {
    padding-left: 0;
    padding-right: 0;
  }

  &.noGradient {
    background-image: unset;
  }

  &.noBackground {
    background: transparent;
  }

  &.lessRadius {
    border-radius: 0.3rem;
  }
  &.reversedColors {
    background-color: var(--BaseSiteColor4);
    color: var(--SiteFontColor1);
    &:active {
      background: var(--GradientDarkenReverse1);
      background-color: var(--BaseSiteColor4);

      &.noBackground {
        background: transparent;
      }
    }

    &.noBackground {
      background: transparent;
    }
  }

  &.disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  &.exit {
    background-color: var(--BaseSiteColor5);
    opacity: 0.8;
    color: var(--SiteFontColor5);

    &:hover {
      opacity: 0.7;
      &:active {
        background: var(--GradientDarkenReverse1);
        background-color: var(--BaseSiteColor5);
      }
    }
  }
  .eliptical {
    border-radius: 50%;
  }
}

.foragerMultiSelect {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 0;
  width: inherit;
  height: inherit;
  bottom: 0;

  &.lowerHalf {
    grid-template-rows: 0 1fr;

    &.shown {
      // transform: translateY(calc(100% - 1.4rem));
    }

    .foragerMultiSelectSearch {
      order: 2;

      &.shown .foragerMultiSelectSearchDisplay {
        border-radius: 0 0 0.3rem 0.3rem;
      }

      .foragerMultiSelectSearchDisplay {
        align-self: center;
      }
    }
    .foragerMultiSelectAnchor {
      order: 1;

      .foragerMultiSelectList {
        transform: translateY(-100%);
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-width: 0.1rem;
        border-bottom: 0;

        &::-webkit-scrollbar {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0.4rem;
        }
      }
    }
  }

  &.alwaysShow {
    grid-template-rows: 1fr max-content;
  }

  .foragerMultiSelectSearch {
    display: grid;
    width: inherit;

    .foragerMultiSelectSearchDisplay {
      width: inherit;
      height: min-content;
      align-self: center;
      border-width: 0.1rem;
      border-style: solid;
      border-color: var(--BaseSiteColor1);
      background-color: var(--BaseSiteColor2);
      border-radius: 0.3rem;
      grid-column: 1;
      grid-row: 1;

      &.current {
        display: flex;
        flex-wrap: wrap;
        column-gap: 0.5rem;
        padding: 0.1rem;

        &.padLeft {
          padding-left: 1rem;
        }

        &.padVertical {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
        }
      }

      &.hidden {
        display: none;
      }

      &.invisible {
        display: none;
      }
    }

    &.shown .foragerMultiSelectSearchDisplay {
      border-radius: 0.3rem 0.3rem 0 0;
    }

    &.alwaysShow .foragerMultiSelectSearchDisplay {
      border-radius: 0;
    }
  }

  .foragerMultiSelectAnchor {
    display: grid;
    position: relative;
    width: inherit;
    height: 0;

    &.alwaysShow {
      height: fit-content;
      border: var(--BaseSiteColor1) solid 0.1rem;
      border-top: 0;
    }

    .foragerMultiSelectList {
      display: grid;
      height: fit-content;
      max-height: 15rem;
      background-color: var(--BaseSiteColor2);
      border: rgba(var(--BaseSiteColor1-rgb), 0.8) solid 0.1rem;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-top-width: 0;
      position: relative;
      z-index: 1;
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        border-left: var(--BaseSiteColor1) solid 0.1rem;
        border-bottom-left-radius: 0.4rem;
      }

      .foragerMultiSelectItem {
        display: grid;
        padding: 0.1rem 0.5rem;

        &.headerWrapper {
          border-bottom: var(--BaseSiteColor1) solid 0.1rem;
          padding-top: 0.2rem;
          padding-bottom: 0;
        }

        &.header {
          width: fit-content;
          font-size: 01rem;
          font-weight: 700;
          color: var(--SiteFontColor1);
          background-color: var(--BaseSiteColor2);
          border-top-left-radius: 0.3rem;
          border-top-right-radius: 0.3rem;
          border: var(--BaseSiteColor1) solid 0.1rem;
          border-bottom: 0;
          text-align: left;
        }
      }
    }
  }
}

.foragerDropDownMenu {
  display: grid;
  grid-template-rows: 1fr 0;
  width: inherit;
  height: inherit;
  bottom: 0;

  &.lowerHalf {
    grid-template-rows: 0 1fr;

    &.showMenu {
      .currentlySelected {
        border-top-right-radius: 0rem;
        border-top-left-radius: 0rem;
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-bottom: unset;
        border-top: 0;
      }
    }
    .currentlySelected {
      order: 2;
    }
    .anchor {
      order: 1;
      position: relative;

      .optionsListWrapper {
        transform: translateY(-100%);
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &.showMenu .currentlySelected {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .currentlySelected {
    width: inherit;
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: auto 0.9rem;
    border-radius: 0.5rem;
    background: var(--Gradient3);
    background-color: var(--BaseSiteColor1);
    color: var(--SiteFontColor2);
    border: var(--BaseSiteColor2) groove 0.1rem;
    order: 1;

    &.showMenu {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 0;
    }

    .label {
      width: 100%;
      height: 100%;
      display: grid;
      align-items: center;
      justify-items: center;
    }

    .arrowContainer {
      font-size: 1rem;
      right: 0.3rem;
      width: 100%;
      height: 100%;
      position: relative;
      display: grid;
      align-items: center;
      justify-items: flex-end;
      text-align: right;
    }

    &.standard {
      background: unset;
      background-color: var(--GanttColor2);
      color: var(--SiteFontColor2);
    }

    &.variant1 {
      background: unset;
      background-color: var(--BaseSiteColor3);
      color: var(--SiteFontColor2);
    }

    &.variant2 {
      background: unset;
      background-color: var(--GanttColor2);
      color: var(--SiteFontColor2);
    }

    &.variant3 {
      background: unset;
      background-color: var(--GanttColor3);
      color: var(--SiteFontColor2);
    }

    &.variant4 {
      background: unset;
      background-color: var(--GanttColor4);
      color: var(--SiteFontColor2);
    }

    &.variant5 {
      background: unset;
      background-color: var(--GanttColor5);
      color: var(--SiteFontColor2);
    }
  }

  &.disabled {
    opacity: 0.5;
  }
  .anchor {
    width: inherit;
    height: 0;
    position: relative;
    z-index: 1;
    order: 2;

    .optionsListWrapper {
      box-sizing: border-box;
      width: inherit;
      height: min-content;
      position: relative;
      border: var(--BaseSiteColor2) groove 0.1rem;
      border-radius: 0.5rem;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      overflow: hidden;

      .optionsList {
        box-sizing: border-box;
        width: inherit;
        height: min-content;
        display: grid;
        grid-auto-rows: max-content;
        background-color: var(--BaseSiteColor4);
        max-height: 10rem;
        overflow-y: scroll;
        overflow-x: hidden;

        .option {
          box-sizing: border-box;
          width: 100%;
          height: max(1.2rem, 100%);
          background: var(--Gradient3);
          background-color: var(--BaseSiteColor2);
          color: var(--SiteFontColor1);
          padding: 0.3rem;

          &:nth-child(odd) {
            background-color: var(--BaseSiteColor4);
          }

          &:hover {
            background-color: rgba(var(--BaseSiteColor1-rgb), 0.7);
            color: var(--SiteFontColor2);
          }

          &.standard {
            background: unset;
            background-color: var(--GanttColor2);
            color: var(--SiteFontColor2);
          }

          &.variant1 {
            background: unset;
            background-color: var(--BaseSiteColor3);
            color: var(--SiteFontColor2);
          }

          &.variant2 {
            background: unset;
            background-color: var(--GanttColor2);
            color: var(--SiteFontColor2);
          }

          &.variant3 {
            background: unset;
            background-color: var(--GanttColor3);
            color: var(--SiteFontColor2);
          }

          &.variant4 {
            background: unset;
            background-color: var(--GanttColor4);
            color: var(--SiteFontColor2);
          }

          &.variant5 {
            background: unset;
            background-color: var(--GanttColor5);
            color: var(--SiteFontColor2);
          }

          &.highlighted {
            background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
            color: var(--SiteFontColor2);
          }
        }

        &::-webkit-scrollbar-thumb {
          border-color: transparent;
        }

        &::-webkit-scrollbar-track {
          background: var(--Gradient3);
          background-color: var(--BaseSiteColor1);
          border-left: var(--BaseSiteColor2) groove 0.1rem;
        }
      }
    }
  }
}

.foragerInputModalContainer {
  width: 30rem;
  max-width: 100%;
  display: grid;
  grid-template-rows: max-content auto max-content;
  row-gap: 1rem;

  .foragerInputModalTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--SiteFontColor1);
  }

  .foragerInputModalMain {
    .foragerInputModalInput {
      width: 100%;

      &.text {
        text-align: left;
      }

      &.textarea {
        text-align: left;
        resize: none;
        height: 5rem;
        background-color: transparent;
        color: var(--SiteFontColor1);
        outline: none;
        border-bottom: 0.1rem solid rgba(var(--BaseSiteColor1-rgb), 0.5);
      }

      &.number {
        text-align: right;
      }

      &.boolean {
        text-align: center;
      }
    }
  }

  .foragerInputModalButtons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
}

.errorModal {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-gap: 0.5rem;
  align-items: center;
  justify-items: center;
  width: min(100%, 90vw);
  height: fit-content;
  padding: 3rem;
  font-size: 1.5rem;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  color: var(--SiteFontColor1);

  .errorInfo {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    grid-gap: 0.5rem;
    align-items: flex-start;
    justify-items: flex-start;
    width: 100%;
    height: fit-content;
    padding: 1rem;
    font-size: 1.5rem;
    word-wrap: break-word;
    overflow-wrap: anywhere;

    .stackLine {
      color: var(--BaseSiteColor5);

      &:nth-child(n + 2) {
        padding-left: 6rem;
      }
      &:nth-child(n + 3) {
        color: unset;
      }
    }
  }
}

.relativeAnchor {
  position: relative;
  z-index: 3;
  display: grid;
}

.foragerTable {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  color: var(--SiteFontColor1);
  background-color: var(--BaseSiteColor4);
  overflow-y: scroll;
  overflow-x: auto;
  scrollbar-gutter: stable;

  &.foragerSubTable {
    grid-row: 2;
    grid-column: 2/-1;
    border: 0.1rem solid rgba(var(--BaseSiteColor1-rgb), 0.5);
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    border-left: 0.1rem solid rgba(var(--BaseSiteColor1-rgb), 0.5);
  }

  .foragerTableItem {
    display: grid;
    grid-auto-flow: column;

    &.header {
      position: sticky;
      top: 0;
      font-weight: bold;
      background-color: var(--BaseSiteColor2);
      background-image: var(--Gradient2);
      z-index: 1;

      .foragerTableItemCell {
        grid-auto-flow: column;
        grid-template-columns: max-content min-content;
        cursor: default;

        &.withPointer {
          cursor: pointer;
        }

        &:hover {
          font-weight: 900;
        }
      }

      &:hover {
        background-color: var(--BaseSiteColor2);
      }
    }

    &:nth-child(2n) {
      background-color: rgba(var(--BaseSiteColor1-rgb), 0.1);
    }

    &.totalRow {
      position: sticky;
      bottom: 0;
      background-color: var(--BaseSiteColor2);
      background-image: var(--Gradient2);
      font-weight: bold;

      &:hover {
        background-color: var(--BaseSiteColor2);
      }
    }

    &:hover {
      background-color: rgba(var(--BaseSiteColor1-rgb), 0.2);
    }

    &.highlighted {
      background-color: rgba(var(--BaseSiteColor1-rgb), 0.7);
      color: var(--SiteFontColor2);
    }

    .foragerTableItemCell {
      grid-row: 1;
      display: grid;
      align-items: center;
      justify-items: center;
      justify-content: center;
      padding: 0.5rem 0.3rem;
      width: 100%;

      &:nth-child(2n) {
        background-color: rgba(var(--BaseSiteColor1-rgb), 0.1);
      }

      .expandIcon {
        display: grid;
        align-self: center;
        justify-self: center;
        font-size: 1.5rem;
        cursor: pointer;
      }

      &.fullCell {
        height: 100%;
        width: 100%;
        padding: 0;
        justify-content: unset !important;
      }

      .activityContent {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: max-content 1fr;
        grid-gap: 0.5rem;
        align-items: center;
      }

      &.hideOnMobile {
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
    }
  }
}

.withPointer {
  cursor: pointer;
}

// specificTableMediaQueries

.ganttItemDetailTaskTable {
  .foragerTableItem {
    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 5rem !important;
    }
  }
}

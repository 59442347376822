@use "./Mixins" as m;

// Base Filter Styles
.filterContainer {
  @include m.fullSize;
  display: grid;
  grid-template-columns: 0.6fr 0.4fr 1fr 0.4fr 0.6fr;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;

  @media (max-width: 1260px) {
    grid-auto-flow: column;
    grid-auto-rows: max-content;
    grid-template-columns: unset;
    justify-content: space-evenly;
  }

  @media (max-width: 1000px) {
    grid-auto-flow: unset;
    grid-auto-columns: unset;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.5rem;
    justify-content: center;
    column-gap: 1rem;
    width: max-content;
  }

  @media (max-width: 400px) {
    grid-auto-flow: unset;
    grid-auto-columns: unset;
    grid-template-columns: auto;
    row-gap: 0.5rem;
    justify-content: center;
    column-gap: 1rem;
  }

  .minimizeIcon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 3rem;
    width: 3rem;
    border-radius: 0.3rem;
    padding: 0.2rem;
    cursor: pointer;

    @media (max-width: 1260px) {
      display: grid;
    }
  }

  .expandFilter {
    width: 0;
  }

  .hiddenOnSmall {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .hiddenOnLarge {
    @media (min-width: 1000px) {
      display: none;
    }
  }
}

//Date Range Filter Styles
.dateRangeContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.3rem;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    order: 4;
  }

  @media (max-width: 400px) {
    order: 5;
  }
}

.dateRangeTitle {
  position: relative;
  width: fit-content;
  font-weight: 700;
  font-size: 0.875rem;
  color: var(--SiteFontColor1);
  cursor: default;

  .itemsBeforeIndicator {
    position: absolute;
    left: -1.5rem;
    top: -0.2rem;
    font-weight: 400;
    font-size: 1.3rem;
    color: var(--BaseSiteColor5);
    cursor: pointer;
  }
}

.dateRangeSelectorContainer {
  display: grid;
  grid-template-columns: 7.5rem 0.75rem 7.5rem;
  grid-column-gap: 0.625rem;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.dateRangeInput {
  border: 0.1rem solid var(--BaseSiteColor1);
  border-radius: 0.2rem;
  width: 7.5rem;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--SiteFontColor1);
  cursor: pointer;
}

.dateRangeSelectorTo {
  font-weight: 700;
  font-size: 0.75rem;
  color: var(--SiteFontColor1);
}

//Content Selector Filter Styles

.contentSelectorContainer {
  @include m.flexCenter(row);

  @media (max-width: 1000px) {
    grid-column: span 2;
    order: -1;
  }

  @media (max-width: 400px) {
    grid-column: unset;
  }
}

.contentSelectorIcon {
  color: var(--SiteFontColor1);
  font-size: 1.25rem;
  cursor: pointer;
}

.contentSelectorTitle {
  font-weight: 700;
  font-size: 1.875rem;
  color: var(--SiteFontColor1);
  width: 10.125rem;
  cursor: default;
}

//Search Filter Styles

.searchContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.15rem;

  @media (max-width: 1000px) {
    order: 5;
  }

  @media (max-width: 400px) {
    order: 4;
  }
}

.searchTitle {
  font-weight: 700;
  font-size: 0.875rem;
  color: var(--SiteFontColor1);
  cursor: default;
}

.searchInputContainer {
}

.searchInput {
  border: 0.1rem solid var(--BaseSiteColor1);
  border-radius: 0.2rem;
  width: 12.5rem;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--SiteFontColor1);
}

// Status Filter Styles

.filterItemContainer {
  @include m.flexCenter(column);
  cursor: pointer;
  color: var(--SiteFontColor1);
}

.filterItemTitle {
  font-weight: 700;
  font-size: 0.875rem;
  cursor: default;
}

.filterItemContent {
  background-color: rgba(var(--BaseSiteColor3-rgb), 0.3);
  border-radius: 0.7rem;
  padding: 0.2rem 0.8rem;
  font-size: 0.75rem;
  font-weight: 700;
  @include m.flexCenter(row);
}

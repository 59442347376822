@use "./Mixins" as m;

.darkModeToggleContainer {
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
}

.darkModeToggleText {
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  padding-top: 0.1rem;
  color: var(--BaseSiteColor1);
}

.toggleSliderContainer {
  display: inline-block;
  height: 1.25rem;
  position: relative;
  width: 2.5rem;

  input {
    display: none;
  }
}

.toggleSlider {
  background-color: var(--BaseSiteColor4);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  border-radius: 0.625rem;
  &::before {
    background-color: var(--BaseSiteColor1);
    bottom: 0.2rem;
    content: "";
    height: 0.875rem;
    left: 0.2rem;
    position: absolute;
    transition: 0.4s;
    width: 0.875rem;
    border-radius: 50%;
  }
}

input:checked + .toggleSlider {
  // background-color: var(--BaseSiteColor1);
  &:before {
    transform: translateX(1.1875rem);
  }
}

.colorSchemeSwitch {
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: var(--BaseSiteColor1);
  cursor: pointer;
}

.menuBackground {
  background-image: var(--Gradient3);
  background-color: var(--SiteBackgroundColor2);
  height: max-content;
  width: max-content;
  z-index: 1;
  border: 0.1rem solid var(--BaseSiteColor1);
  border-radius: 0.5rem;
  box-shadow: 0 0 0.625em 0 #000000;
  overflow: hidden;
}

.menuContainer {
  display: grid;
  row-gap: 0.5rem;
  grid-template-rows: 1.6rem max-content auto;

  background-color: rgba(var(--BaseSiteColor1-rgb), 0.2);
  color: white;
  padding: 0.3rem;
}

.menuSignOutContainer {
  display: grid;
  grid-template-columns: max-content auto max-content;
  column-gap: 0.5rem;
  width: 100%;
  height: 100%;
  justify-content: stretch;
  align-items: center;
  padding: 0 0.5rem;
}

.menuHeader {
  display: grid;
  width: max-content;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 20rem;
  color: var(--SiteFontColor4);
  font-size: 1.5rem;
  font-weight: 800;
  cursor: default;
}

.menuDimmerContainer {
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  .menuDimmer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0.2);
    padding-left: 7rem;
    padding-top: 0.5rem;
    z-index: 9999999999999;

    @media (max-width: 1260px) {
      padding-left: 4rem;
    }
  }
}

.sidebarButtonsContainer {
  display: grid;
  grid-auto-flow: row;
  row-gap: 0.5rem;

  .sidebarSection {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1.6rem;
    row-gap: 0.3rem;

    .sidebarSectionTitle {
      display: grid;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 800;
      border-bottom: 0.15rem solid rgba(var(--BaseSiteColor1-rgb), 0.5);
      color: var(--SiteFontColor1);
      cursor: default;
    }
  }
}

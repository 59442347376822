@use "./Mixins" as m;

.screenContainer {
  @include m.fullSize;
}

.alertBox {
  @include m.flexCenter(column);
  position: absolute;
  top: 0;
  left: 50vw;
  transform: translate(calc(-50% + 2.8125rem), 0);
  z-index: 100;
  overflow: hidden;
}

.alertBoxItem {
  display: grid;
  grid-template-columns: auto 2rem;
  height: min-content;
  max-height: 0;
  animation: alert-cycle 3s linear;
  width: 18.75rem;
  background-color: var(--BaseSiteColor4);
  outline: 0.1rem solid var(--Outline1);
  outline-offset: -1px;
  color: var(--SiteFontColor1);

  &.longAlert {
    animation: alert-cycle 6s linear;
  }

  &.errorAlert {
    height: min-content;
    background-color: var(--BaseSiteColor5);
    animation: alert-error 25s ease-in-out;
    color: var(--SiteFontColor1);
    font-size: 1.2rem;
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;

    .alertBoxItemClose {
      visibility: visible;
    }
  }
}

.alertBoxItemMessage {
  @include m.flexCenter(row);
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.alertBoxItemClose {
  @include m.flexCenter(row);
  height: 100%;
  width: 100%;
  visibility: hidden;
}

@keyframes alert-cycle {
  0% {
    max-height: 0;
  }

  20% {
    max-height: 10rem;
  }

  80% {
    max-height: 10rem;
  }

  100% {
    max-height: 0;
  }
}

@keyframes alert-error {
  0% {
    max-height: 0;
  }

  2% {
    max-height: 10rem;
  }

  98% {
    max-height: 10rem;
  }

  100% {
    max-height: 0;
  }
}

input {
  outline: none;
  background-color: var(--BaseSiteColor4);
  color: var(--SiteFontColor1);
  border-color: var(--BaseSiteColor1);
}

select {
  outline: none;
  background-color: var(--BaseSiteColor4);
  color: var(--SiteFontColor1);
  border-color: var(--BaseSiteColor1);
}

.inputError {
  // outline-color: red !important;
  // outline-style: solid !important;
  // outline-offset: -2px;
  border-color: var(--BaseSiteColor5);
  border-style: solid;
}

.loadingScreen {
  visibility: hidden;
  @include m.flexCenter(column);
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  color: var(--SiteFontColor2);
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 99;

  &.reports {
    visibility: visible;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

.loadingScreenText {
  font-size: 1.5rem;
  margin-top: 1rem;
  text-shadow: 0.15rem 0.15rem 0.15rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.25);
}

.loadingScreenIcon {
  width: 6.25rem;
  height: 6.25rem;
}

.spinning {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loginContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 31.25rem;
  height: 20rem;
  background-color: var(--BaseSiteColor4);
  border: 0.15rem solid var(--BaseSiteColor1);
  border-radius: 0.3rem;
  box-shadow: 0 0 0.3rem 0 #555555;
}

.changePassword {
  width: 34.375rem;
}

.loginHeaderIcon {
  display: grid;
  grid-template-columns: 7rem auto;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  height: 6.25rem;
  max-height: 6.25rem;

  .loginCircle {
    display: grid;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    height: 4.5rem;
  }
}

.loginInputsContainer {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.loginInputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0 1rem;
}

.loginInputLabel {
  display: grid;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  font-size: 1.5625rem;
  font-weight: 700;
  color: var(--SiteFontColor1);
}

.loginInput {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-self: flex-start;

  input {
    margin: 0;
  }
}

.loginInputPasswordContainer {
  display: grid;
  width: 15rem;
  grid-template-columns: 1fr 1rem;
  grid-gap: 0.5rem;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  justify-self: flex-start;

  input {
    width: 100%;
    margin: 0;
    padding-left: 0.5rem;
  }
}

.loginPasswordEye {
  color: var(--SiteFontColor1);
  cursor: pointer;
}

// .loginCheckboxContainer {
//   margin-top: 1.25rem;
//   margin-bottom: 0.625rem;
// }

.loginCheckboxContainer {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  font-weight: 700;
  color: var(--SiteFontColor1);
  font-size: 1.125rem;
}

.loginButton {
  width: 6.875rem;
  height: 1.6875rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0.3rem;
  border: 0.1rem solid var(--BaseSiteColor1);
  border-radius: 0.3rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  background-color: var(--BaseSiteColor1);
  color: var(--SiteFontColor2);
  cursor: pointer;
  :active {
    background-color: var(--BaseSiteColor1);
  }
}

.tooltipError {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  border: 0.15rem solid var(--BaseSiteColor5) !important;
}

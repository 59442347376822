@use "./Mixins" as m;

.IQLogoButton {
  font-size: 3rem;
  color: var(--SiteLogoColor1);
  cursor: pointer;
}

.IQLogoButton > svg {
  display: block;
}

.IQBodyContainer {
  position: relative;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 1fr min-content;
  grid-template-columns: min-content 1fr;
  background: var(--Gradient2);
  background-color: var(--SiteBackgroundColor2);
  overflow: hidden;

  @media screen and (max-width: 700px) {
    &.showSidebar {
      grid-template-columns: unset;
      grid-template-rows: 1fr;
    }
  }
}

.messageContainer {
  display: grid;
  grid-template-columns: 1fr 2.75rem 2.75rem;
  grid-template-rows: 2.75rem 2.75rem minmax(2.5rem, 1fr);
  justify-content: flex-end;
  align-items: center;
  width: auto;
  max-width: 85%;
  font-weight: 500;
  color: var(--SiteFontColor1);

  .avatarWrapper {
    display: grid;
    height: 100%;
    width: 100%;
    grid-row: 1/-2;
    grid-column: 2/-1;
    font-size: 5rem;
    border-radius: 1rem;
    background-color: var(--BaseSiteColor1);
    color: var(--SiteFontColor2);
    z-index: 1;
    box-shadow: 0 0.15rem 0.2rem var(--BaseSiteColor1);
    border: solid 0.2rem var(--BaseSiteColor2);

    .avatarIcon {
      box-sizing: border-box;
      // box-shadow: inset 0 0 0.2rem var(--BaseSiteColor2);
      border-radius: 0.9rem;
      height: 100%;
      width: 100%;
      padding: 0.3rem;
      filter: drop-shadow(0 0 0.2rem var(--BaseSiteColor2));
    }
  }
  .message {
    display: grid;
    grid-column: 1/-2;
    grid-row: 2/-1;
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.1);
    padding: 1rem 3.5rem 1rem 1.5rem;
    border-radius: 1rem;
    overflow-wrap: break-word;
    white-space: pre-line;
    user-select: text;
    border: solid 0.2rem var(--BaseSiteColor1);
  }

  &.assistant {
    justify-content: flex-start;
    grid-template-columns: 2.5rem 2.5rem 1fr;
    .avatarWrapper {
      grid-column: 1/-2;
      grid-row: 1/-2;
      background-color: var(--BaseSiteColor2);
      border: solid 0.2rem var(--BaseSiteColor1);
      color: var(--SiteFontColor1);
      .avatarIcon {
        // box-shadow: inset 0 0 0.2rem var(--BaseSiteColor1);
        filter: drop-shadow(0 0 0.2rem var(--BaseSiteColor1));
      }
    }
    .message {
      grid-column: 2/-1;
      grid-row: 2/-1;
      padding: 1rem 1.5rem 1rem 3.5rem;
      border: solid 0.2rem var(--BaseSiteColor2);
      background-color: rgba(var(--BaseSiteColor2-rgb), 0.2);
    }
  }
}

.user {
  align-self: flex-end;
  text-align: right;
}

.assistant {
  align-self: flex-start;
  text-align: left;
  padding-right: 2rem;
}

.IQContainer {
  display: grid;
  width: 100%;
  background-color: var(--SiteBackgroundColor2);
  height: 100%;
  grid-template-columns: min-content 1fr;
  overflow: hidden;
}

.IQBody {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding: 0;

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.clearChatButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: min-content;
}

.IQChatContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 1.5rem 3rem;
  font-size: 1.5rem;

  code {
    font-size: 1.25rem;
    background-color: var(--BaseSiteColor2);
    padding: 0.5rem 1rem;
    min-width: fit-content;
    color: var(--SiteFontColor1);
    display: inline-block;
  }
}

.iqSideBar {
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-content: space-evenly;
  background-color: rgba(var(--BaseSiteColor3-rgb), 0.3);
  border-right: solid 0.1rem var(--BaseSiteColor1);
  z-index: 1;

  .iqSideBarButton {
    display: grid;
    justify-content: center;
    align-content: center;
    font-size: 4rem;
    color: var(--SiteBackgroundColor2);
    cursor: pointer;

    &.selected {
      color: var(--BaseSiteColor2);
    }

    &.disabled {
      opacity: 0.3;
      cursor: default;
    }

    .iqSideBarLogoWrapper {
      height: 4rem;
      width: 4rem;
    }

    svg {
      stroke-width: 0.03rem;
      stroke: var(--SiteFontColor1);
      filter: drop-shadow(0 0.1rem 0.07rem var(--BaseSiteColor1));
    }
  }
}

.iqExpandedSideBar {
  display: grid;
  grid-template-rows: min-content 1fr;
  width: 0rem;
  height: 100%;
  overflow: hidden;
  grid-template-columns: 1fr min-content;
  grid-row: span 2;
  z-index: 1;
  background-color: var(--SiteBackgroundColor2);

  &.showing {
    width: 22rem;
    box-shadow: 0 0 0.625em 0 #000000;
    border-right: solid 0.1rem var(--BaseSiteColor1);
    z-index: 2;

    @media screen and (max-width: 700px) {
      position: absolute;
      top: 0;
      box-shadow: unset;
    }

    .hideTab {
      grid-column: 2;
    }
  }

  .titleBar {
    width: 100%;
    height: 4rem;
    display: grid;
    background-color: var(--BaseSiteColor1);
    color: var(--SiteFontColor2);
    font-size: 2rem;
    font-weight: 600;
    justify-content: flex-start;
    align-content: center;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }

  .hideTab {
    display: grid;
    width: 0;
    position: relative;
    // grid-column: 2;
    grid-row: 1 / -1;
    justify-content: center;
    align-content: center;
    align-self: center;

    .displayedTab {
      height: 4rem;
      width: 1.5rem;
      transform: translateY(-50%);
      position: fixed;
      justify-content: center;
      align-content: center;
      color: var(--SiteFontColor2);
      background-color: var(--BaseSiteColor1);
      border-radius: 0 0.5rem 0.5rem 0;
      cursor: pointer;
    }
  }

  .helpBar {
    overflow-y: auto;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;

    .helpBarTopic {
      display: grid;
      padding: 0.75rem 0.5rem;
      border-bottom: solid 0.1rem var(--BaseSiteColor1);
      place-items: center;
      row-gap: 0.5rem;

      .helpBarTitle {
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--SiteFontColor1);
      }

      .helpBarBody {
        font-size: 1.1rem;
        color: var(--SiteFontColor1);
        display: block;
        max-height: 0;
        transition: max-height 0.5s ease-in-out;
        overflow: hidden;
      }

      &:hover {
        background-color: rgba(var(--BaseSiteColor1-rgb), 0.2);
      }

      &.open {
        .helpBarBody {
          max-height: 100vh;
        }
      }
    }
  }
}

.DocumentManagementModal {
  .titleBar {
    width: 100%;
    height: 4rem;
    display: grid;
    background-color: var(--BaseSiteColor1);
    color: var(--SiteFontColor2);
    font-size: 2rem;
    font-weight: 600;
    justify-content: flex-start;
    align-content: center;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }
}

.iqSearchBar {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.5rem;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;

  .searchInput {
    height: 2.5rem;
    border-radius: 1.25rem;
    padding: 0.5rem 1rem;
    min-height: 2.5rem;
    font-size: 1rem;
    text-align: left;
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.05);
    color: var(--SiteFontColor1);
    border: solid 0.1rem var(--BaseSiteColor1);
    box-sizing: border-box;
    width: 100%;
  }

  .iqSearchButton {
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    font-size: 2rem;
    display: grid;
  }
}

.knowledgeBaseListFooter {
  display: grid;
  max-height: 100%;
  overflow: hidden;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--BaseSiteColor1);
  color: var(--SiteFontColor2);
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
}

.chooseKnowledgeBase {
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  .knowledgeBaseListHeader {
    display: grid;
    max-height: 100%;
    overflow: hidden;
    grid-template-columns: 2rem 1fr 2rem 0.9rem;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0.5rem;
    background-color: var(--BaseSiteColor1);
    color: var(--SiteFontColor2);
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;

    .knowledgeBaseListHeaderTitle {
      display: grid;
      align-content: center;
      text-align: left;
    }

    .knowledgeBaseListHeaderIcon {
      display: grid;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center;
    }
    .infoIcon {
      font-size: 1.5rem;
      height: 1.6rem;
    }
  }

  .knowledgeBaseList {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;

    .knowledgeBaseListItem {
      display: grid;
      grid-template-columns: 1fr 2rem;
      justify-content: center;
      align-content: center;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      color: var(--SiteFontColor1);
      border-bottom: solid 0.1rem var(--BaseSiteColor1);
      border-right: solid 0.1rem var(--BaseSiteColor1);
      cursor: pointer;

      &.selected {
        background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
        color: var(--SiteFontColor2);

        .infoIcon {
          color: var(--SiteFontColor2);
        }
      }

      &:hover {
        background-image: var(--Gradient3);
      }

      .knowledgeBaseListItemTitle {
        font-size: 1.1rem;
        font-weight: 500;
        text-align: left;
        width: 100%;
      }

      .infoIcon {
        font-size: 1.5rem;
        color: var(--BaseSiteColor1);
        height: 1.6rem;
      }
    }
  }
}

.AddKnowledgeBaseContainer {
  display: grid;
  grid-template-rows: min-content 1fr min-content;

  .inputContainer {
    display: grid;
    grid-template-rows: min-content 1fr;
    row-gap: 0.5rem;
    height: 100%;
    padding: 0.5rem;
    .inputLabel {
      display: grid;
      grid-auto-flow: column;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--SiteFontColor1);
      text-align: left;

      &.required {
        grid-template-columns: 1rem 1fr;
        .requiredIndicator {
          color: var(--BaseSiteColor5);
          font-size: 2rem;
          height: 0;
          position: relative;
          top: -0.8rem;
        }
      }
    }
    .inputEntry {
      height: 100%;
      font-size: 1.5rem;
      border-radius: 0.5rem;
      min-height: 2.5rem;
      padding: 0 0.5rem;
      border-style: solid;

      &.area {
        color: var(--SiteFontColor1);
        background-color: var(--BaseSiteColor4);
        font-size: 1.2rem;
      }
    }
    .foragerDropDownMenu {
      height: 2rem;
    }
    .inputDisplay {
      display: grid;
      text-align: left;

      &.list {
        display: grid;
        grid-auto-flow: row;
        align-content: flex-start;
        row-gap: 0.5rem;
      }
    }
  }
}

.IQChatBarContainer {
  display: grid;
  // transform: translate(0, -6rem);
  min-height: fit-content;
  color: var(--SiteFontColor1);
  height: min-content;
  width: 100%;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  padding: 1rem 1.2rem 1rem 1rem;

  .inputContainer {
    display: grid;
    grid-template-columns: 1fr 4rem;
    column-gap: 1rem;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
    position: relative;
    max-width: 100%;

    .knowledgeBasesSelectedContainer {
      display: grid;
      grid-auto-flow: column;
      padding: 0 2rem;
      justify-self: flex-start;
      justify-content: flex-start;
      width: 100%;
      max-width: 100%;
      overflow-x: hidden;

      .knowledgeBasesSelected {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0.3rem 0.5rem;
        border-radius: 1.5rem 1.5rem 0 0;
        border: solid 0.1rem var(--BaseSiteColor1);
        border-bottom: none;
        text-align: left;
        column-gap: 0.5rem;
        row-gap: 0.5rem;
        width: 100%;
        max-width: 100%;
        background-color: var(--BaseSiteColor2);
        &.pleaseSelect {
          background-color: var(--BaseSiteColor1);
        }
        .selectedKnowledgeBase {
          font-size: 1.1rem;
          font-weight: 600;
          padding: 0.3rem 1rem;
          background-color: var(--BaseSiteColor4);
          border: solid 0.1rem var(--BaseSiteColor1);
          border-radius: 1rem;
          &.pleaseSelect {
            border: none;
            background-color: transparent;
            color: var(--SiteFontColor2);
          }
        }
      }
    }

    .chatBar {
      height: 4rem;
      border-radius: 2rem;
      padding: 1rem;
      min-height: 3rem;
      font-size: 1.25rem;
      background-color: rgba(var(--BaseSiteColor1-rgb), 0.05);
      color: var(--SiteFontColor1);
      border: solid 0.1rem var(--BaseSiteColor1);
      box-sizing: border-box;
      width: 100%;
      grid-column: 1;
      grid-row: 2;
      // box-shadow: 0 0.3rem 0.2rem var(--BaseSiteColor1);

      @media screen and (max-width: 1260px) {
        width: 100%;
        padding: 0.5rem;
      }

      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }
  }

  .clearChatButton {
    font-size: 2.5rem;
    display: grid;
    width: 3.5rem;
    height: 3.5rem;
  }

  .selectKBDropdownContainer {
    display: grid;
    column-gap: 0.75rem;
    grid-template-columns: 1fr min-content;
    min-width: 60%;
    align-items: center;
    justify-self: center;
    max-height: 90%;
    font-size: 1.25rem;
    color: var(--SiteFontColor1);
    input {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      border-radius: 1rem;
      font-size: 1.25rem;
      width: max-content;
    }

    .askIQButton {
      font-size: 2.5rem;
      max-height: unset;
      border-radius: 50%;
    }

    .foragerMultiSelect {
      width: 100%;
    }

    @media screen and (max-width: 1268px) {
      padding: 0;
      width: 75%;
    }

    @media screen and (max-width: 1000px) {
      width: 60vw;
      width: 60dvw;
      justify-self: center;
    }

    @media screen and (max-width: 700px) {
      width: 85vw;
      width: 85dvw;
    }

    @media screen and (max-width: 400px) {
      width: 90vw;
      width: 90dvw;
    }
  }

  .askQuestionWrapper {
    background-color: var(--BaseSiteColor1);
    padding: 0.5rem;
    border-radius: 50%;
    color: var(--SiteFontColor2);
    font-size: 3rem;
    grid-column: 2;
    grid-row: 2;
    cursor: pointer;
    svg {
      display: block;
    }
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
      svg {
        pointer-events: none;
      }
    }
  }

  .ChooseChatModelContainer {
    display: grid;
    justify-self: flex-start;
    align-self: center;
    width: 50%;

    @media screen and (max-width: 1268px) {
      width: 100%;
    }

    @media screen and (max-width: 1000px) {
      width: 60vw;
      width: 60dvw;
      justify-self: center;
    }

    @media screen and (max-width: 700px) {
      width: 85vw;
      width: 85dvw;
    }

    @media screen and (max-width: 400px) {
      width: 90vw;
      width: 90dvw;
    }

    .ChooseChatModelDropdown {
      font-size: 1.25rem;
      font-weight: 500;
      width: 100%;
      min-height: 3rem;
      background-color: var(--BaseSiteColor2);

      .label {
        padding: 0.1rem 1rem;
      }

      .currentlySelected {
        border: 2px var(--BaseSiteColor2) solid;
        border-radius: 0.5rem;
      }
    }
  }
}

.isThinking {
  min-width: 30%;
}

.IQHeader {
  &.IQ {
    background-color: var(--BaseSiteColor1);
    @include m.flex(row, flex-start, center);
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--SiteFontColor2);
    padding: 0.9375rem;
    background: var(--Gradient3);
    background-color: var(--BaseSiteColor1);
    border-radius: 0.625rem;
  }
}

// IQ File Upload Modal
.IQFileUploadModal {
  color: var(--SiteFontColor1);
  max-height: 70vh;
  max-height: 70dvh;
  display: grid;
  grid-auto-rows: 2.5rem max-content min-content;
  width: 40vw;
  max-width: 40vw;
  overflow: hidden;
}

.IQFilesActions {
  background-color: rgba(var(--BaseSiteColor3-rgb), 0.3);
  border-radius: 0.625rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.3rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 700;
  min-height: 8dvh;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  row-gap: 0.3rem;
}

.IQFileUploadModalHeaderTitle {
  font-size: 1.875rem;
  font-weight: 700;
  margin-left: 0.625rem;
}

.IQFilesChooseFileInput {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.IQFileUploadModalPreviewPlaceholder {
  height: 30dvh;
  font-size: 4rem;
  display: grid;
  justify-content: center;
  align-content: center;
}

.IQFileUploadModalPreviewContainer {
  pointer-events: none;
  padding: 1rem 2rem;
  overflow: hidden;
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.IQFileUploadModalDragOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: grid;
  justify-content: center;
  align-content: center;
  background-color: rgba(var(--BaseSiteColor1-rgb), 0.7);
  border-radius: 0.625rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.3rem 0.625rem;
  min-height: 8dvh;
}

.IQFileUploadModalPreview {
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.IQFileUploadModalPreview * {
  pointer-events: none;
}

.IQMessageLinkWrapper {
  display: inline-flex;
  column-gap: 0.5rem;
  align-items: center;

  .IQMessageLinkIcon {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--BaseSiteColor1);
    cursor: pointer;
    width: auto;
    height: auto;

    &:hover {
      color: var(--Gradient3);
    }
  }
}

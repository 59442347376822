@use "./Mixins" as m;

//Admin Modal

.adminModalContainer {
  width: 28.125rem;
}

.adminModalButton {
  @include m.flexCenter(row);
  height: 2.1875rem;
  width: 100%;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);
  border: none;
  color: var(--SiteFontColor4);
  border-radius: 0.3rem;
  font-size: 1.375rem;
  font-weight: 700;
  cursor: pointer;
}

.editIcon {
  color: var(--SiteFontColor1);
  background-color: var(--BaseSiteColor4);
  font-size: 1.875rem;
  cursor: pointer;
  border-radius: 0.3rem;
  outline: solid 0.15rem var(--BaseSiteColor1);
}

.featureIcon {
  color: var(--SiteFontColor1);
  background-color: var(--BaseSiteColor4);
  font-size: 1.875rem;
  cursor: pointer;
  border-radius: 0.3rem;
  outline: solid 0.15rem var(--BaseSiteColor1);
}

.managementAddIcon {
  color: var(--SiteFontColor2);
  font-size: 1.875rem;
  cursor: pointer;

  &.invertedColor {
    color: var(--SiteFontColor1);
    cursor: pointer;
  }
}

.permissionsIcon {
  color: var(--SiteFontColor1);
  background-color: var(--BaseSiteColor4);
  font-size: 1.875rem;
  cursor: pointer;
  border-radius: 0.3rem;
  outline: solid 0.15rem var(--BaseSiteColor1);
}

//Add Gantt Item Modal

.addModalContainer {
  width: 23.125rem;
}

.addModalBody {
  @include m.flexCenter(column);
  color: var(--SiteFontColor1);
  input {
    height: 100%;
    width: 100%;
    border: none;
    outline: 0.1rem solid var(--BaseSiteColor1);
    padding: 0;
    margin: 0;
  }
}

.addModalBodyItem {
  display: grid;
  grid-template-columns: 10.625rem 12.5rem;
  margin-bottom: 0.625rem;
}

.addModalBodyItemInput {
  height: 100%;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  outline: 0.1rem solid var(--BaseSiteColor1);
  padding: 0;
  margin: 0;
}

.addModalBodyItemLabel {
  font-size: 1.1875rem;
  font-weight: 700;
  color: var(--SiteFontColor1);
  margin-top: auto;
  margin-left: auto;
  margin-right: 0.625rem;
}
.addModalButton {
  @include m.flexCenter(row);
  height: 2.1875rem;
  width: 8.4375rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.625rem;
  background-color: var(--BaseSiteColor1);
  border: none;
  color: var(--SiteFontColor2);
  border-radius: 0.625rem;
  font-size: 1.375rem;
  font-weight: 700;
  cursor: pointer;
}

.addModalFooter {
  @include m.flexCenter(row);
  height: 3.125rem;
  width: 100%;
}
.addModalHeader {
  @include m.flexCenter(row);
  height: 3.125rem;
  width: 100%;
  font-size: 1.375rem;
  font-weight: 700;
  color: var(--SiteFontColor1);
}

//Add Admin Item Modal

.addAdminItemBody {
  border-radius: 0.625rem;
  overflow: hidden;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor4);
  box-shadow: rgba(0, 0, 0, 0.3) 0 0.3rem 0.3rem 0;
}

.addAdminItemCheckBox {
  height: 1.25rem;
  width: auto;
  border: rgba(var(--BaseSiteColor1-rgb), 0.5) 0.1rem solid;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--SiteFontColor1);
  margin-right: 0.625rem;

  &.state {
    width: 16rem;
    height: 1.875rem;
  }

  margin-left: 1.25rem;
  float: left;
  text-align: left;
  transform: scale(1.8);
}
.addAdminItemContainer {
  width: 56.25rem;
  // height: 29.375rem;
}

.addAdminItemContactInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1.5625rem 1.5625rem 1.5625rem;
  grid-column-gap: 0.625rem;
  grid-row-gap: 0.625rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-bottom: 3.125rem;
}

.addAdminItemEmailInputContainer {
  @include m.flexCenter(row);
  width: 28.125rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5625rem;
}

.addAdminItemFormContainer {
  display: grid;
  grid-template-columns: 10rem 20rem 10rem 20rem;
  gap: 1rem;
  padding: 1.5rem;
  justify-content: flex-start;
  align-items: center;
  text-align: left;

  &.oneColumn {
    grid-template-columns: 20rem 20rem;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 10rem 20rem;
  }
}

.addAdminItemHeader {
  @include m.flexCenter(row);
  height: 3.125rem;
  width: 56.25rem;
}

.adminItemInput {
  height: 1.5625rem;
  width: 15.675rem;
  border: solid 0.1rem var(--BaseSiteColor1);
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--SiteFontColor1);

  &.state {
    width: 16rem;
    height: 1.875rem;
  }

  &.email {
    grid-column: span 2;
  }
}
.adminInputContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content auto;
  height: 3.125rem;
  width: 100%;
}

.adminItemInputLabel {
  color: var(--SiteFontColor1);
  font-size: 1.5625rem;
  font-weight: 700;

  &.email {
    grid-column: span 2;
    text-align: right;

    @media (max-width: 1000px) {
      grid-column: unset;
      text-align: left;
    }
  }
}

.adminItemInputMiddleInitial {
  width: 1.5625rem !important;
}

.addAdminItemNameInputContainer {
  @include m.flexCenter(row);
  width: 28.125rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5625rem;
}
.addAdminItemNotRequired {
  margin-left: 0.15rem;
}

.addAdminItemPersonalInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1.5625rem 1.5625rem;
  grid-column-gap: 0.625rem;
  grid-row-gap: 0.625rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.addAdminItemResetPasswordContainer {
  display: grid;
  grid-template-rows: 1.5625rem;
  align-items: flex-end;
  margin-left: 0.625rem;
  margin-right: 5.3125rem;
}

.addAdminItemResetPasswordInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  font-size: 1.25rem;
  grid-column: span 4;
}

.addAdminItemRequiredField {
  color: var(--SiteFontColor3);
  font-size: 1.25rem;
  font-weight: 700;
  margin-right: 0.3rem;
}

.addAdminItemTitle {
  color: var(--SiteFontColor1);
  font-size: 1.875rem;
  font-weight: 700;
}

.adminItemButton {
  @include m.flexCenter(row);
  height: 1.5625rem;
  margin-left: auto;
  margin-right: auto;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor4);
  border: none;
  color: var(--SiteFontColor1);
  border-radius: 1.5625rem;
  font-size: 1.125rem;
  font-weight: 700;
  cursor: pointer;
}

// Job Modal

.ganttItemModalBodySection {
  width: 100%;
  height: 100%;
  border-radius: 0.625rem;
  background: var(--Gradient4);
  background-color: var(--BaseSiteColor4);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0.3rem 0.3rem 0;
}

.ganttItemModalContainer {
  display: grid;
  height: calc(100vh - 7rem);
  height: calc(100dvh - 7rem);
  width: 92vw;
  max-height: 100%;
  max-width: 100%;
}

.ganttItemModalTitle {
  font-size: 1.875rem;
  font-weight: 700;
  margin-left: 0.625rem;
  cursor: pointer;
}

.left {
  margin-right: auto;
}

.right {
  margin-left: auto;
}

// Task Modal

// .taskModalContainer {
//   height: 40.625rem;
//   width: 75rem;
// }

// .taskModalHeader {
//   height: 3.125rem;
//   width: 100%;
// }

// .taskModalTitle {
//   font-size: 1.875rem;
//   font-weight: 700;
//   margin-left: 0.625rem;
//   cursor: default;
// }

// .taskModalBodySection {
//   border-radius: 0.625rem;
//   background-color: var(--BaseSiteColor4);
//   overflow: hidden;
//   box-shadow: rgba(0, 0, 0, 0.3) 0 0.3rem 0.3rem 0;
// }

.ganttItemDetailListHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 6fr;
  border-bottom: rgba(var(--BaseSiteColor1-rgb), 0.5) 0.1rem solid;
  font-size: 1.2rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: sticky;
  top: 0;
  background: var(--Gradient2);
  background-color: var(--BaseSiteColor2);

  &.activity {
    grid-template-columns: 10rem 9rem 15rem auto;
    justify-content: flex-start;

    @media (max-width: 700px) {
      grid-template-columns: 10rem auto;
    }
  }

  &.changeLogDetail {
    grid-template-columns: 10rem auto;
    justify-content: flex-start;

    @media (max-width: 1268px) {
      grid-template-columns: 1fr;
    }
  }

  &.task {
    grid-template-columns: 9rem 9rem 7rem 1fr 8rem 4.5rem;

    @media (max-width: 700px) {
      grid-template-columns: 4.5rem 10rem 1fr;

      .gotoTask {
        grid-row: span 2;
        order: -1;
      }
    }
  }
}

.ganttItemDetailListContainer {
  display: grid;
  grid-auto-rows: min-content;
  overflow-y: auto;

  &.task {
    scrollbar-gutter: stable;
  }
}

.ganttItemDetailListItem {
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 1fr 1fr 1fr 6fr;
  width: 100%;

  &.activity {
    grid-template-columns: 10rem 9rem 15rem auto;

    @media (max-width: 700px) {
      grid-template-columns: 10rem auto;

      &:nth-child(2) {
        order: 1;
      }

      &:nth-child(2) {
        order: 3;
      }

      &:nth-child(3) {
        order: 2;
      }

      &:nth-child(3) {
        order: 4;
      }
    }
  }

  &.changeLogDetail {
    grid-template-columns: 10rem auto;

    @media (max-width: 1268px) {
      grid-template-columns: 1fr;

      &:nth-child(2) {
        order: 1;
      }

      &:nth-child(2) {
        order: 3;
      }

      &:nth-child(3) {
        order: 2;
      }

      &:nth-child(3) {
        order: 4;
      }
    }
  }

  &.task {
    grid-template-columns: 9rem 9rem 7rem 1fr 8rem 4.5rem;

    @media (max-width: 700px) {
      grid-template-columns: 4.5rem 10rem auto;

      .gotoTask {
        grid-row: span 2;
        order: -1;
      }
    }
  }

  &.mainInfo {
    min-width: 12rem;
  }

  &:nth-child(2n) {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.2);
  }

  &:hover {
    color: var(--SiteFontColor2);
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.6);
  }
}

.ganttItemDetailListHeaderItem {
  display: grid;
  align-items: flex-start;
  padding: 0.4rem 0.4rem 0.4rem 1rem;
  font-size: 1.3rem;
  text-align: left;
  height: 100%;

  &:nth-child(odd) {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.12);
  }

  &.gotoTask {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.4rem;
  }

  &.alignRight {
    text-align: right;
  }

  &.mainInfo {
    min-width: 12rem;
  }
}

.ganttItemDetailListItemValue {
  display: grid;
  align-items: flex-start;
  padding: 0.4rem 0.4rem 0.4rem 1rem;
  font-size: 1.3rem;
  text-align: left;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;

  &:nth-child(odd) {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.05);
  }

  &.gotoTask {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    padding: 0;
    cursor: pointer;
  }

  &.expandable {
    grid-template-columns: auto min-content;
    .expandButton {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--BaseSiteColor1);
      border-radius: 50%;
      font-size: 1.5rem;
      color: var(--SiteFontColor2);
      cursor: pointer;
    }

    &.changeLogDetail {
      grid-template-columns: 5rem auto min-content;
    }
  }

  &.changeLogDetail {
    padding: 0;
  }

  &.alignRight {
    text-align: right;
  }

  &.noLeftPadding {
    padding-left: 0;
  }

  .contentButtonFirst {
    display: grid;
    grid-template-columns: min-content 1fr;
  }
}

.ganttItemInformationBody {
  height: 100%;
  width: 100%;
}

.ganttItemInformationContainer {
  width: 100%;
  height: 100%;
  position: relative;
  color: var(--SiteFontColor1);

  textarea {
    border: none;
    outline: 0.1rem solid var(--BaseSiteColor1);
    outline-offset: -1px;
    padding: 0;
    height: 100%;
    width: 100%;
    padding-inline-start: 0;

    &.updateComment {
      min-height: 6rem;
      min-width: 90%;
      background-color: var(--BaseSiteColor4);
      padding: 0.5rem;
      border-radius: 0.5rem;
      border: 0.1rem solid var(--BaseSiteColor1);
    }
  }
  &.task {
    width: 90%;
    background-color: rgba(var(--BaseSiteColor3-rgb), 0.3);
    margin-bottom: 0.625rem;
    border-radius: 0.625rem;
    margin-left: auto;
    margin-right: auto;
    .ganttItemInformationTitleContainer {
      cursor: pointer;
    }
  }
}

.ganttItemInformationDatesContainer {
  @include m.flexCenter(row);

  .ganttItemInformationColumnContainer {
    min-width: 10rem;
  }
}

.ganttItemInformationHeader {
  height: 6.25rem;
  width: 100%;
}

.ganttItemInformationTitle {
  display: grid;
  grid-template-columns: min-content 1fr;
  height: 100%;
  font-size: 2rem;
  font-weight: 700;
  padding: 1rem;
  border: rgba(var(--BaseSiteColor1-rgb), 0.5) 0.1rem solid;
  background: var(--Gradient4);
  background-color: var(--BaseSiteColor1);
  justify-content: flex-start;
  justify-items: flex-start;
  color: var(--SiteFontColor2);
  position: sticky;
  top: 0;
  z-index: 1;

  .required {
    display: grid;
    grid-auto-flow: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    .requiredIndicator {
      color: var(--BaseSiteColor5);
    }
  }

  @media (max-width: 700px) {
    border-radius: 0.625rem;
  }
}

.ganttItemInformationAddTitle {
  display: grid;
  font-size: 2rem;
  font-weight: 600;
  max-width: 100%;
  word-wrap: break-word;
  color: var(--SiteFontColor1);
  cursor: default;
}

.ganttItemStatus {
  display: grid;
  grid-template-columns: 12rem auto;
  column-gap: 1rem;
  padding-right: 0.5rem;
}

.ganttItemStatusContainer {
  position: relative;
  display: grid;
  grid-template-rows: auto min-content;
  row-gap: 0.8rem;
  width: 100%;
  max-width: 100%;
  background: var(--Gradient4);
  background-color: var(--BaseSiteColor4);
  border-right: rgba(var(--BaseSiteColor1-rgb), 0.5) 0.1rem solid;
  overflow: hidden;

  @media (max-width: 700px) {
    border: 0;
    background: transparent;
    background-color: transparent;
  }
}

.ganttItemStatusList {
  display: grid;
  grid-auto-rows: min-content;
  min-height: 100%;
  row-gap: 0.8rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0.5rem;

  @media (max-width: 700px) {
    height: fit-content;
  }
}

.ganttItemStatusValue {
  display: grid;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.1rem;
  width: 100%;
  height: 100%;
  cursor: default;
  color: var(--SiteFontColor1);
  text-align: left;
  flex-wrap: wrap;
  word-wrap: break-word;
  overflow-wrap: anywhere;

  &.job {
    grid-template-columns: 1fr min-content;
    cursor: pointer;

    .gotoJobArrowContainer {
      display: grid;
      justify-content: center;
      align-items: center;
      color: var(--SiteFontColor1);

      .gotoJobArrow {
        height: 2rem;
        width: 2rem;
      }
    }
  }
}

.ganttItemStatusDropDownContainer {
  display: grid;
  align-items: center;
  justify-content: flex-start;
}

.ganttItemStatusDropDown {
  width: 20rem;
  height: 2rem;
}

.ganttItemViewPhotosButton {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1rem;
}

.ganttItemFilesNumber {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  width: 2rem;
  height: 2rem;
  border: 1px solid;
  border-radius: 50%;
  line-height: 1;
  padding: 0;
}

.ganttItemStatusValueEdit {
  height: min-content;
  width: 20rem;
  align-self: center;
  border-width: 0 0 0.1rem 0;
  border-style: solid;
  border-color: rgba(var(--BaseSiteColor1-rgb), 0.5);
  background-color: transparent;

  &.title {
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
    text-align: left;
    border-color: var(--SiteFontColor2);
    color: var(--SiteFontColor2);

    @media (max-width: 700px) {
      width: 85%;
    }
  }

  &.dropdown {
    border: none;
    padding: 0;
  }

  &.date {
    height: 2rem;
  }

  &.checkbox {
    height: max-content;
    width: max-content;
    justify-self: flex-start;
    margin: 0;
  }

  &.formWidth2 {
    grid-column: span 2;

    @media (max-width: 1000px) {
      grid-column: span 1;
    }
  }

  &.textarea {
    word-wrap: break-word;
    overflow-wrap: anywhere;
    width: 20rem;
    resize: none;
    outline: none;
    height: auto;
    overflow: hidden;
    border-width: 0 0 0.1rem 0;
    border-style: solid;
    border-color: rgba(var(--BaseSiteColor1-rgb), 0.5);
  }
}

.ganttItemDetailValueContainer {
  display: grid;
  flex-direction: column;
  border: rgba(var(--BaseSiteColor1-rgb), 0.5) 0.1rem solid;
  border-radius: 0.625rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  user-select: text;
  width: 100%;

  @media (max-width: 700px) {
    height: fit-content;
    max-height: 40rem;
  }

  &.valuesHidden {
    display: none;
  }

  &.description {
    min-height: 10rem;

    @media (max-width: 700px) {
      min-height: 20rem;
    }

    &::-webkit-scrollbar-track {
      border-left: 0.1rem solid rgba(var(--BaseSiteColor1-rgb), 0.5);
    }
  }

  &.add {
    height: 100%;
  }
}

.ganttItemDetailValue {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1.1rem;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  cursor: default;
  text-align: left;
  color: var(--SiteFontColor1);

  p {
    margin: 0.2rem 0;
  }

  &.description {
    overflow: auto;
    scrollbar-gutter: stable;
    min-height: 1.7rem;

    &.add {
      height: 100%;
    }
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.status {
    width: 9.375rem;
    text-align: center;
  }

  &.clickable {
    cursor: pointer;
  }

  &:hover .showOnParentHover {
    display: block;
    transform: none;
  }
}

// // a better name
// .JobItemInformationActionsContainer {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 0.9375rem;
//   margin-bottom: 0.625rem;
// }

.ganttItemInformationActionIcon {
  margin: 0.1rem;
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--BaseSiteColor1);
  border-radius: 0.3rem;
  padding: 0.2rem;
}

.ganttItemInformationColumnContainer {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.ganttItemInformationDetailContainer {
  display: grid;
  grid-auto-rows: minmax(auto, min-content) auto;
  align-content: flex-start;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  color: var(--SiteFontColor1);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 1.2rem 0.5rem;
  scrollbar-gutter: stable;

  @media (max-width: 700px) {
    height: fit-content;
    overflow-y: hidden;
    max-height: fit-content;
  }

  &.add {
    grid-template-rows: min-content 1fr;
  }
}

.ganttItemTimeEntryButtons {
  display: grid;
  grid-auto-columns: max-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  width: 100%;
  border-bottom: 0.1rem solid rgba(var(--BaseSiteColor1-rgb), 0.5);
  background: var(--Gradient1);
  background-color: var(--BaseSiteColor4);

  @media (max-width: 700px) {
    overflow: scroll;
    border: 0;
    background: transparent;
    background-color: transparent;
  }
}

.ganttItemInformationDescriptionContainer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 40rem auto;
  grid-template-rows: max-content auto;

  @media (max-width: 1260px) {
    grid-template-columns: 1fr 1.5fr;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content max-content auto;
    overflow-y: scroll;
  }
}

.ganttItemInformationDescriptionLeft {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr auto;
  row-gap: 0.2rem;
  width: 100%;
}

.ganttItemInformationDescriptionRight {
  margin-left: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}

.ganttItemInformationActionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.5rem;
  padding: 1rem;
  width: 100%;
  height: 100%;
}

.ganttItemInformationButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  border-radius: 1rem;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);
  color: var(--SiteFontColor2);
  font-size: 1.25rem;
  font-weight: 700;
  cursor: pointer;
  @include m.flexCenter(row);

  &.clock {
    width: fit-content;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.cancel {
    background-color: var(--BaseSiteColor5);
    opacity: 0.8;
    &.disabled {
      opacity: 0.5;
    }
  }
}

.ganttItemInformationSaveClose {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  column-gap: 1rem;
  width: 100%;
  height: 100%;
}

.ganttItemInformationLabel {
  font-weight: 700;
  color: var(--SiteFontColor1);
  min-height: 3rem;
  font-size: 1.3rem;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  width: 100%;
  border-right: rgba(var(--BaseSiteColor1-rgb), 0.5) 0.2rem solid;
  // border-bottom: rgba(var(--BaseSiteColor1-rgb), 0.5) 0.1rem solid;
  border-radius: 0.5rem;
  padding-right: 0.5rem;
  text-align: right;
  .required {
    display: grid;
    grid-auto-flow: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    .requiredIndicator {
      color: var(--BaseSiteColor5);
      font-size: 0.9rem;
    }
  }

  &:hover {
    background: var(--Gradient2);
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.1);
  }

  &.detail {
    font-size: 1.7rem;
    border-right: none;
    border-bottom: 0.1rem solid rgba(var(--BaseSiteColor1-rgb), 0.5);
    padding: 0;
    cursor: pointer;

    .taskShowInactiveLabel {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      justify-content: flex-start;
      column-gap: 0.5rem;
      font-size: 1.1rem;
      font-weight: 500;
      height: 100%;

      input {
        cursor: pointer;
      }
    }

    .AddCommentSection {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      align-items: flex-start;
      justify-content: center;
      column-gap: 0.5rem;
      height: fit-content;
      padding: 0.3rem 0.3rem 0.3rem 0;

      @media (max-width: 700px) {
        grid-auto-flow: row;
        justify-items: flex-end;
        padding-right: 0;
      }
    }

    @media (max-width: 700px) {
      grid-auto-flow: row;
      justify-items: flex-end;
      padding-right: 0;
    }
  }

  .minimizeLabel {
    display: grid;
    grid-template-columns: min-content auto min-content;
    align-items: center;
    align-self: flex-start;
    justify-content: flex-start;
    justify-self: flex-start;
    flex-direction: row;
    column-gap: 0.5rem;
    padding: 0 0 0 0.5rem;
    width: 100%;
    min-height: 3rem;

    .minimizeIcon {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 0.3rem;
      padding: 0.2rem;

      &.requiredDetail {
        color: var(--BaseSiteColor5);
        height: 1.1rem;
        width: 1.1rem;
      }
    }
  }
}

.ganttItemInformationRowContainer {
  margin-left: 3.125rem;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  column-gap: 1.25rem;
  width: 100%;
  color: var(--SiteFontColor1);

  &:hover {
    box-shadow: 0 -0.1rem 0 rgba(var(--BaseSiteColor1-rgb), 0.5) inset;
  }

  &.Description {
    overflow: auto;
    scrollbar-gutter: stable;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.status {
    width: 9.375rem;
    text-align: center;
  }
  &.clickable {
    cursor: pointer;
  }

  &:hover .showOnParentHover {
    display: block;
    transform: none;
  }
}

.ganttItemInformationEditIconContainer {
  position: absolute;
  @include m.flexCenter(row);
  font-size: 1.0625rem;
  right: 0.3rem;
  top: 0.3rem;
  color: var(--SiteFontColor2);
  cursor: pointer;
  padding: 0.2rem;
}

//Admin Item Management Modal

.adminItemCustomerSettingsHeader {
  color: var(--BaseSiteColor1);
  display: flex;
  font-size: 2rem;
  font-weight: 700;
}

.adminModalSection {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 0.625rem;
  padding: 0.5rem;
}

.adminModal {
  display: grid;
  height: fit-content;
  min-height: 30rem;
  width: fit-content;
  min-width: 30rem;
  color: var(--SiteFontColor1);

  &.customerSettings {
    width: 100%;
    height: max-content;
    grid-template-rows: min-content auto min-content;
  }
}

.adminModalBody {
  display: grid;
  align-content: flex-start;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  overflow-y: auto;
  scrollbar-gutter: stable;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor4);

  &.permissionManagement {
    height: 40rem;
    background-color: var(--BaseSiteColor4);
    justify-content: flex-start;
  }

  &.paddedInternal {
    @include m.flexCenter(column);
    justify-content: flex-start;
    padding: 1.25rem 0.35rem 1.25rem 1.25rem;
    row-gap: 3rem;
    background: unset;
    background-color: unset;
  }

  &::-webkit-scrollbar {
    background-color: var(--BaseSiteColor1);
    border-left: 0.1rem solid rgba(var(--BaseSiteColor1-rgb), 0.6);
  }
}

.adminModalInnerTable {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-rows: min-content max-content;
  border: 0.1rem solid var(--BaseSiteColor1);
  border-radius: 0.5rem;

  .adminModalBody {
    overflow: unset;
  }
}

.adminModalBodyContainer {
  width: 100%;
  height: 100%;
  border-radius: 0.625rem;
  overflow: hidden;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);

  &.customerSettings {
    border-radius: 0;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-gutter: stable;
    background-color: var(--BaseSiteColor2);
    display: grid;
    grid-row-gap: 2rem;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0.5rem 0.1rem 0.5rem 1rem;
  }

  &.optionSelection {
    padding: 0.5rem;
    display: grid;
    grid-auto-flow: row;
    row-gap: 0.5rem;
    border-radius: unset;
    overflow: unset;
    background: unset;
    background-color: unset;
  }

  &.impersonateSelection {
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 0.8fr auto;
    row-gap: 0.5rem;
    border-radius: unset;
    // // overflow: unset;
    // background: unset;
    background-color: unset;
  }

  &.tableOnly {
    display: grid;
    grid-template-rows: max-content auto max-content;
    max-height: calc(100vh - 7rem);
    max-height: calc(100dvh - 7rem);
  }
}

.impersonateWarning {
  display: grid;
  grid-column: span 2;
  color: var(--SiteFontColor3);
}

.adminModalBodyHeader {
  height: 3.125rem;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor4);
  display: grid;
  grid-template-columns: 4fr 5fr 1.4fr 1.4fr;
  outline: solid 0.1rem var(--BaseSiteColor1);
  scrollbar-gutter: stable;
  overflow-y: scroll;

  &.clientManagement {
    grid-template-columns: 4fr 1fr;
  }

  &.customizeManagement {
    grid-template-columns: 3fr 2.5fr 2.5fr 2fr 1fr;
  }

  &.userManagement {
    grid-template-columns: 4fr 5fr 1.4fr 1.4fr 1.4fr;
  }

  &::-webkit-scrollbar {
    border-left: 0.1rem solid var(--BaseSiteColor1);
  }
}

.adminModalDropDownContainer {
  display: grid;
  justify-items: center;
  align-content: center;
  height: 100%;
  width: 18rem;
  min-width: 100%;
  padding: 0.5rem;
}

.adminModalBodyHeaderItem {
  font-size: 1.25rem;
  font-weight: 600;
  border: solid 0.1rem var(--BaseSiteColor1);
  width: 100%;
  height: 100%;
  @include m.flexCenter(row);
  cursor: default;
  overflow: hidden;

  &.hiddenOnSmall {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  &.customizeItem {
    &.name {
      @media (max-width: 1000px) {
        grid-column: span 2;
      }
    }
  }

  &.statusItem {
    @media (max-width: 1000px) {
      &.name {
        grid-column: span 3;
        order: 3;
      }

      &.active {
        grid-column: span 2;
        order: 5;
      }

      &.complete {
        grid-column: span 2;
        order: 6;
      }

      &.color {
        grid-column: span 2;
        order: 7;
      }

      &.delete {
        order: 4;
      }
    }
  }
}

.adminModalBodyItemContainer {
  height: fit-content;
  min-height: 2.5rem;
  width: 100%;
  display: grid;
  background: var(--Gradient2);
  background-color: rgba(var(--BaseSiteColor1-rgb), 0.9);

  &.header {
    height: 3.125rem;
    background: var(--Gradient3);
    background-color: var(--BaseSiteColor4);
  }

  &.customerItem {
    grid-template-columns: auto 12rem 4rem 4rem;
  }

  &.clientItem {
    grid-template-columns: 1fr 4rem;
  }

  &.userItem {
    grid-template-columns: auto 25rem 4rem 4rem 4rem;
  }

  &.customizeItem {
    grid-template-columns: 14rem 16rem auto 10rem 3rem;
    &.invalid {
      background-color: rgba(var(--BaseSiteColor5-rgb), 0.5);
    }
  }

  &.statusItem {
    grid-template-columns: 5rem 5rem auto 9rem 9rem 10rem 2em;
  }

  &:nth-child(even) {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.6);
  }

  @media (max-width: 1000px) {
    &.customerItem {
      grid-template-columns: auto 4rem 4rem;
    }

    &.clientItem {
      grid-template-columns: auto 4rem;
    }

    &.userItem {
      grid-template-columns: auto 4rem 4rem 4rem;
    }

    &.customizeItem {
      grid-template-columns: 16rem 16rem 3rem;
      grid-auto-flow: column;
      grid-template-rows: 2.5rem 2.5rem;
      height: 5rem;
      &.invalid {
        background-color: rgba(var(--BaseSiteColor5-rgb), 0.5);
      }
    }

    &.statusItem {
      grid-template-columns: 5rem 5rem 5rem 5rem 5rem 5rem;
      height: 5rem;
    }
  }
}

.adminModalTableValue {
  color: var(--SiteFontColor2);
  font-size: 1.25rem;
  font-weight: 600;
  border: solid 0.1rem var(--BaseSiteColor1);
  padding: 0 0.5rem;
  @include m.flexCenter(row);
  width: inherit;
  overflow-wrap: anywhere;

  input {
    color: unset;
    width: 12rem;
    font-size: 1.25rem;
    font-weight: 600;
    border-bottom-color: var(--BaseSiteColor2);
  }

  &.delete {
    color: var(--BaseSiteColor5);
    cursor: pointer;
  }

  .foragerDropDownMenu {
    width: 8rem;
  }

  &.customizeItem {
    &.field {
      .foragerDropDownMenu {
        width: 14rem;
      }
    }

    &.section {
      .foragerDropDownMenu {
        width: 12rem;
      }
    }

    &.name {
      @media (max-width: 1000px) {
        grid-column: span 2;
      }
    }

    .ganttItemStatusValueEdit {
      width: 14rem;
      text-align: center;
    }
  }

  &.order {
    .foragerDropDownMenu {
      width: 4rem;
    }
  }

  &.hiddenOnSmall {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  &.statusItem {
    @media (max-width: 1000px) {
      &.name {
        grid-column: span 3;
        order: 3;
      }

      &.active {
        grid-column: span 2;
        order: 5;
      }

      &.complete {
        grid-column: span 2;
        order: 6;
      }

      &.color {
        grid-column: span 2;
        order: 7;
      }

      &.delete {
        order: 4;
      }
    }
  }
}

.adminModalTitleBar {
  height: min-content;
  min-height: 3rem;
  width: 100%;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-template-rows: min-content;
  color: var(--SiteFontColor2);
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);
  padding: 0 0.5rem;

  &.threeSection {
    grid-auto-columns: unset;
    grid-auto-flow: unset;
    grid-template-columns: 13rem 1fr 13rem;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  &.spaced {
    justify-content: space-evenly;
  }
}

.adminModalHeader {
  height: 3.125rem;
  width: 100%;
  @include m.flexCenter(row);
}

.adminModalSelect {
  width: 12.5rem;
  height: 1.875rem;
  border-radius: 0.3rem;
  color: var(--SiteFontColor2);
  font-size: 1.25rem;
  font-weight: 700;
  outline: solid 0.1rem var(--BaseSiteColor1);

  @media (max-width: 1000px) {
    &.role {
      order: -1;
    }
  }
}

.adminModalSectionContainer {
  width: 100%;
  display: grid;

  &:nth-child(even) {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.1);
  }
}

.adminModalSectionTitle {
  font-size: 1.25rem;
  font-weight: 700;
  border-top: 0.1rem solid rgba(var(--BaseSiteColor1-rgb), 0.5);
  border-bottom: 0.1rem solid rgba(var(--BaseSiteColor1-rgb), 0.5);
}

.adminModalTitle {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-content: center;
  font-size: 1.875rem;
  font-weight: 700;
  cursor: default;

  &.rightJustified {
    justify-content: flex-end;
  }

  .addIcon {
    padding-right: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.changePasswordModalContainer {
  display: grid;
  grid-template-rows: 3rem min-content min-content min-content 3rem;
  row-gap: 1rem;
}

.permissionContainer {
  display: grid;
  grid-template-columns: 1.5rem 1fr;
}

.permissionCheckbox {
  height: 0.9375rem;
  width: 0.9375rem;
  margin-right: auto;
}

.permissionTitle {
  font-size: 0.9375rem;
  font-weight: 700;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  color: var(--SiteFontColor1);
}

.permissionDisabled {
  color: var(--SiteFontColor1);
  opacity: 0.3;
}

.itemAssignmentModalContainer {
  width: 25rem;
  // height: max-content;
  display: grid;
  grid-template-rows: 2rem auto 2.5rem;
  row-gap: 1rem;
  color: var(--SiteFontColor1);
}

.itemAssignmentModalTitle {
  font-size: 1.875rem;
  font-weight: 700;
  color: var(--SiteFontColor2);
}

.itemAssignmentModalBodySearch {
  width: inherit;
  grid-column: span 2;

  input {
    height: 100%;
    width: inherit;
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0;
    color: var(--SiteFontColor1);
    border-bottom: 0.1rem solid var(--BaseSiteColor1);
    padding: 0;
    box-sizing: border-box;
  }

  &:focus-within .optionsListAnchor {
    display: block;
  }
}

.optionsListAnchor {
  position: relative;
  width: inherit;
  height: 0;
  align-self: flex-end;
  justify-self: baseline;
  display: none;
}

.optionsToAssign {
  position: relative;
  width: inherit;
  max-height: 15rem;
  display: grid;
  border: 0.1rem solid var(--BaseSiteColor1);
  border-top: 0;
  border-radius: 0.5rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  row-gap: 0.2rem;
  background-color: var(--BaseSiteColor4);
  z-index: 1;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding: 0.3rem;
  grid-gap: 0.3rem;
  color: rgba(var(--BaseSiteColor1-rgb), 0.5);

  &.empty {
    row-gap: 0;
  }
}

.itemAssignmentModalBodyListSection {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.3rem;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  align-items: center;
  justify-items: center;
  overflow-y: auto;
  scrollbar-gutter: stable;
  background-color: transparent;
  width: inherit;

  &.optionsToAdd {
    position: absolute;
    width: 100%;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    max-height: 20rem;
    bottom: 0;

    &.hidden {
      display: none;
    }
  }
}

.itemAssignmentModalItem {
  color: var(--SiteFontColor2);
  padding: 0.1rem 0.5rem;
  border-radius: 0.625rem;
  background: var(--Gradient5);
  background-color: var(--BaseSiteColor1);
  @include m.flexCenter(row);
  cursor: pointer;

  &.assigned:hover {
    color: var(--BaseSiteColor5);
  }
}

.itemAssignmentModalSave {
  @include m.flexCenter(row);
  height: 100%;
  width: 100%;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);
  border: none;
  color: var(--SiteFontColor4);
  border-radius: 0.3rem;
  font-size: 1.375rem;
  font-weight: 700;
  cursor: pointer;
}

.JobFilesActions {
  background-color: rgba(var(--BaseSiteColor3-rgb), 0.3);
  border-radius: 0.625rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.3rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 700;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  row-gap: 0.3rem;
}

.JobFilesChooseFileLabel {
  width: 100%;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;

  .foragerButton {
    grid-row: 1;
    grid-column: 1;
  }

  .JobFilesChooseFileInput {
    display: grid;
    width: 20rem;
    height: 100%;
    opacity: 0;
    grid-row: 1;
    grid-column: 1;
    z-index: -1;
  }
}

.JobFilesModal {
  height: 70vh;
  height: 70dvh;
  max-height: 70vh;
  max-height: 70dvh;
  display: grid;
  grid-auto-rows: 2.5rem max-content auto;
  width: 70vw;
  max-width: 70vw;
  overflow: hidden;
}

.JobFileImagePreviewNotFoundIcon {
  width: 100%;
  max-width: fit-content;
  height: 16rem;
  max-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: var(--SiteFontColor1);
  cursor: pointer;
}

.JobFilesUploadButton {
  width: fit-content;
}

.JobFilesItem {
  background-color: rgba(var(--BaseSiteColor3-rgb), 0.3);
  color: var(--SiteFontColor1);
  display: grid;
  grid-template-columns: 10rem 1fr max-content max-content max-content;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;

  &.clicked {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.6);
  }
  &:nth-child(odd) {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.4);
    &.clicked {
      background-color: rgba(var(--BaseSiteColor1-rgb), 0.6);
      color: var(--SiteFontColor2);
      &:hover {
        background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
      }
    }
  }

  &.deleting {
    background-color: rgba(var(--BaseSiteColor5-rgb), 0.5);
    color: var(--SiteFontColor1);
    &:nth-child(odd) {
      background-color: rgba(var(--BaseSiteColor5-rgb), 0.6);
      &.clicked {
        background-color: rgba(var(--BaseSiteColor5-rgb), 0.8);
        color: var(--SiteFontColor2);
        &:hover {
          background-color: rgba(var(--BaseSiteColor5-rgb), 0.8);
        }
      }
    }

    &:hover {
      background-color: rgba(var(--BaseSiteColor5-rgb), 0.8);
    }
  }

  &:hover {
    color: var(--SiteFontColor2);
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
  }
}

.JobFilesItemPart {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: 100%;
  max-height: 100%;
  width: 100%;
  padding: 0.5rem;

  &.download {
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      color: var(--BaseSiteColor5);
    }
  }

  &.copy {
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      color: var(--BaseSiteColor5);
    }
  }

  &.delete {
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      color: var(--BaseSiteColor5);
    }
  }

  img {
    height: 3.5rem;
    width: 5rem;
    object-fit: cover;
  }
}

// .JobRemoveTextDecoration {
//   text-decoration: none;
// }

.JobBodySection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 100%;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  @media (max-width: 1268px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    overflow: hidden;
  }
}

.JobImageIcon {
  transform: scale(4);
}

.JobImagePreviewSection {
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border: var(--BaseSiteColor1) solid 0.1rem;
  border-radius: 0.625rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;

  img {
    overflow: clip;
    width: fit-content;
    height: fit-content;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
  }

  .react-pdf__Document {
    align-self: flex-start;
  }

  @media (max-width: 1268px) {
    border-radius: 0;
    border: var(--BaseSiteColor1) solid 0.1rem;
    object-fit: contain;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      overflow: hidden;
    }
  }
}

.JobItemSection {
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: grid;
  grid-auto-rows: 4rem;
  overflow-y: auto;
  scrollbar-gutter: stable;
  overflow-x: hidden;
  border: var(--BaseSiteColor1) solid 0.1rem;
  border-bottom-right-radius: 0.625rem;
  box-sizing: border-box;

  @media (max-width: 1268px) {
    border-bottom-left-radius: 0.625rem;
  }
}

.filteredModalItemContainer {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  width: 100%;
  height: 100%;
}

.filteredModalItemNamesContainer {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  border-right: solid medium;
  border-top: solid medium;
  font-weight: 600;
}

.filteredModalItemValuesContainer {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  border-top: solid medium;
}

.filteredModalItems {
  font-size: 1.25rem;
  box-sizing: border-box;
  height: 100%;
  text-align: center;
  width: 100%;
  padding: 0.2rem 1rem;
  color: var(--SiteFontColor2);
  border: solid 0.1rem var(--BaseSiteColor1);
  background-color: rgba(var(--BaseSiteColor1-rgb), 0.9);
  @include m.flexCenter(column);
  row-gap: 0.5rem;
  cursor: pointer;
  &:nth-child(odd) {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.6);
  }
  select {
    width: 100%;
  }
}

// Filter modal

.filterModalContainer {
  display: grid;
  grid-template-rows: 2.5rem auto 2.5rem;
  color: var(--BaseSiteColor1);
}

.filteredModalTitleBar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);
  &.bottomBar {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }
  &.topBar {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
  }
}

// Time Clock Modal

.timeClockModalContainer {
  display: grid;
  grid-template-rows: 5rem auto;
  height: 80vh;
  height: 80dvh;
  color: var(--SiteFontColor1);
}

.timeClockModalButtonsTop {
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);
  color: var(--SiteFontColor2);

  // @media (max-width: 1268px) {
  //   .mainScreenContentHeaderClockContainer {
  //     padding-right: 4rem;
  //   }
  // }
}

.timeClockModalButtonsTopLeft {
  @include m.flex(row, flex-start, center);
  column-gap: 0.625rem;
}

.timeClockModalButtonsTopRight {
  @include m.flex(row, flex-end, center);
  column-gap: 0.625rem;
  padding: 0.5rem 0.5rem 0 0;
}

.timeClockModalButton {
  @include m.flexCenter(row);
  height: 1.25rem;
  padding: 1rem;
  color: var(--SiteFontColor2);
  border-radius: 0.625rem;
  background: var(--Gradient5);
  background-color: var(--BaseSiteColor1);
  cursor: pointer;
  font-size: 1.5625rem;
  font-weight: 700;
}

.timeClockModalTitle {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  color: var(--SiteFontColor2);
  font-size: 1.5625rem;
  font-weight: 600;
}

.timeClockModalDropDownContainer {
  width: 19rem;
  height: 2rem;
}

.addEditTimeModalBodyItemDropDownContainer {
  @include m.flex(row, flex-end, center);
  width: 19rem;
  height: 2rem;

  &.notes {
    height: fit-content;

    textarea {
      width: 100%;
    }
  }
}

.timeClockModalDropDown {
  width: inherit;
  height: inherit;
}

.timeClockModalBodyContainer {
  display: grid;
  grid-template-columns: 1fr 0;
  height: 100%;
  border-radius: 0.625rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background: var(--Gradient4);
  background-color: var(--BaseSiteColor1);
  overflow: hidden;
  padding: 0.3125rem;
}

.timeClockModalBodyFilters {
  position: absolute;
  right: 0;
  display: grid;
  grid-auto-rows: min-content;
  justify-content: center;
  align-content: flex-start;
  row-gap: 1rem;
  flex-wrap: wrap;
  padding: 0.5rem 0.5rem 1rem 1rem;
  height: 3rem;
  width: 3rem;
  background: var(--Gradient1);
  background-color: var(--BaseSiteColor1);
  border-bottom-left-radius: 100%;
  border: 0.1rem solid var(--BaseSiteColor2);
  border-top: 0;
  border-right: 0;

  &.showFilters {
    height: fit-content;
    width: fit-content;
    border-bottom-left-radius: 1rem;
  }
}

.timeClockModalBodyRight {
  display: grid;
  height: 100%;
  max-height: 100%;
  align-content: flex-start;
  align-items: flex-start;
  border-radius: 0.625rem;
  border: var(--BaseSiteColor2) solid 0.1rem;
  overflow: hidden;
}

.timeClockModalBodyRightItems {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
}

.timeEntryContainer {
  display: grid;
  grid-template-columns: 18rem 18rem 6rem 6rem 6rem 1fr;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 3rem;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor2);

  &:nth-child(odd) {
    background-color: var(--BaseSiteColor4);
  }

  &.header {
    top: 0;
    position: sticky;
    min-height: 2rem;
    background: var(--Gradient5);
    background-color: var(--BaseSiteColor2);

    .timeEntryItem {
      color: var(--SiteFontColor2);
      background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  &.dayDivider {
    grid-template-columns: 36rem 12rem 6rem 1fr;
    background-color: var(--BaseSiteColor2);
    color: var(--SiteFontColor2);
    position: sticky;
    top: 2rem;

    @media (max-width: 1000px) {
      grid-template-columns: 2fr 1fr 1fr 0fr;
      top: 8rem;
    }

    .timeEntryItem {
      color: var(--SiteFontColor2);
      background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
    }
  }
}

.timeEntryItem {
  display: grid;
  height: inherit;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--SiteFontColor1);
  outline: 0.1rem solid var(--BaseSiteColor1);
  padding: 0.3rem 0;

  @media (max-width: 1000px) {
    &.name {
      grid-column: span 2;
    }

    &.time {
      grid-auto-flow: column;
      column-gap: 1rem;
    }
  }

  &.name {
    cursor: pointer;
  }
}

.timeEntryEditButton {
  color: var(--SiteFontColor2);
  height: min-content;
  width: min-content;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  background: var(--Gradient5);
  background-color: var(--BaseSiteColor1);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
}

.addEditTimeModalContainer {
  display: grid;
  grid-template-rows: auto auto;
  color: var(--SiteFontColor1);
}

.addEditTimeModalHeader {
  @include m.flex(row, flex-start, center);
  font-size: 1.5625rem;
  font-weight: 700;
  color: var(--SiteFontColor2);
  padding: 0.9375rem;
  background-color: var(--BaseSiteColor1);
}

.addEditTimeModalBody {
  display: grid;
  grid-auto-rows: min-content;
  row-gap: 1.25rem;
  padding: 0.625rem 1.25rem;
  background-color: var(--BaseSiteColor4);
  border-bottom: 0.1rem solid var(--BaseSiteColor1);
}

.addEditTimeModalBodyItemContainer {
  display: grid;
  grid-template-columns: 6.5rem auto;
  justify-content: flex-start;
  align-content: center;
  column-gap: 0.9375rem;
  width: 100%;

  .foragerDropdown {
    width: 95%;
  }

  input {
    text-align: center;
  }
}

.addEditTimeModalBodyItem {
  font-weight: 700;
  color: var(--SiteFontColor1);
  &.left {
    font-size: 1.375rem;
    @include m.flex(row, flex-end, center);
    width: 100%;
  }
  &.right {
    @include m.flex(row, flex-start, center);
    background-color: var(--BaseSiteColor2);
    width: 19rem;
    border-radius: 0.625rem;
    border-style: inset;
  }
  &.text {
    font-size: 1.25rem;
    @include m.flex(row, center, center);
    width: 19rem;
    height: 100%;
  }
}

.addEditTimeModalFooter {
  @include m.flex(row, flex-end, center);
  column-gap: 0.625rem;
  padding: 0.625rem 1.25rem;
  background-color: var(--BaseSiteColor4);
}

.addEditTimeModalFooterButton {
  @include m.flex(row, center, center);
  padding: 0.625rem 0;
  width: 7rem;
  color: var(--SiteFontColor2);
  border-radius: 0.625rem;
  background: var(--Gradient5);
  background-color: var(--BaseSiteColor1);
  cursor: pointer;
  font-size: 1.375rem;
  font-weight: 700;
}

// Report Modal

.reportsModalContainer {
  display: grid;
  grid-template-rows: 0 min-content 1fr;
  background-color: var(--BaseSiteColor2);
  background-image: var(--Gradient4);
  height: 82vh;
  height: 82dvh;
  width: 95vw;
  overflow: visible;
}

.modalHeader {
  &.reports {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 22rem 1fr 22rem;
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--SiteFontColor1);
    padding: 0.5rem;
    height: 4rem;
    border-radius: 0.625rem;
  }
}

.modalBody {
  &.reports {
    display: grid;
    justify-self: center;
    grid-template-columns: 1fr;
    border-top: solid 0.1rem rgba(var(--BaseSiteColor1-rgb), 0.5);
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    color: var(--SiteFontColor1);
  }
}

.modalBodySection {
  display: grid;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  &.reportOptions {
    color: var(--SiteFontColor1);
    position: absolute;
    left: 0;
    min-height: 3.25rem;
    width: 3.25rem;
    border-radius: 0.5rem;

    border-left: 0;
    border-top: 0;

    &.showMenu {
      grid-template-rows: 1fr;
      row-gap: 2rem;
      width: 22rem;
      max-height: unset;
      height: 82vh;
      background: var(--Gradient4);
      background-color: var(--BaseSiteColor2);
      border-right: 0.1rem solid var(--BaseSiteColor1);
      padding: 1rem;
      overflow-y: auto;
    }

    .menuButton {
      position: absolute;
      top: 0;
      left: 0;
      height: 3rem;
      width: 3rem;
    }
  }

  &.reportTable {
    position: relative;
    grid-template-rows: min-content auto;
    border-radius: 0.625rem;
    overflow: hidden;
    border: var(--BaseSiteColor2) 0.1rem solid;

    &.payrollSubList {
      border-radius: 0;
      overflow: hidden;
      border: none;
    }
  }
}

.settingsContainer {
  display: grid;
  grid-auto-rows: min-content;
  justify-content: center;
  align-content: space-between;
  height: 100%;
  width: 100%;
  row-gap: 0.5rem;
}

.setting {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;

  &.dates {
    grid-auto-flow: row;
    align-content: center;
    row-gap: 0.5rem;
  }

  &.buttons {
    align-content: end;
    height: 100%;
  }

  &.required {
    grid-template-columns: 0 1fr;
  }

  .requiredIndicator {
    display: grid;
    justify-self: center;
    align-self: center;
    position: absolute;
    right: 0;
    color: var(--BaseSiteColor5);
  }
}

.reportsModalBodyButton {
  @include m.flex(row, center, center);
  padding: 0.625rem 0;
  width: 9rem;
  color: var(--SiteFontColor1);
  border-radius: 0.5rem;
  background: var(--Gradient5);
  background-color: var(--BaseSiteColor4);
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 700;
}

.settingLabel {
  &.reports {
    display: grid;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 700;
  }
}

.modalDropDown {
  &.reports {
    width: 15rem;
    height: 2.8rem;
    font-size: 1.2rem;
    font-weight: 700;
  }
}

.reportsModalBodyFilterItemDate {
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 0.5rem;
  padding-top: 0.5rem;

  input {
    text-align: center;
    width: 13rem;
    height: 2.8rem;
    border-radius: 0.625rem;
    font-size: 1.25rem;
    font-weight: 700;
  }
}

.reportsModalBodyHeaderItem {
  padding: 0.625rem;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
  color: var(--SiteFontColor2);
  text-align: left;
  border: var(--BaseSiteColor2) solid 0.1rem;

  @media (max-width: 1268px) {
    &.name {
      grid-column: span 2;
    }
    &.date {
      grid-column: span 2;
    }
    &.lastColumn {
      grid-column: span 2;
      text-align: right;
    }
    &.expand {
      align-content: flex-start;
      grid-row: 1 / 10;
    }
  }
}

.table {
  background-color: var(--BaseSiteColor1);
  height: 100%;
  overflow-y: scroll;

  &.payrollSubList {
    overflow-y: hidden;
  }

  &::-webkit-scrollbar {
    border-left: 0.2rem solid var(--BaseSiteColor1);
  }
}

.reportsModalReportsButton {
  @include m.flex(row, center, center);
  color: var(--SiteFontColor2);
  padding: 0.625rem 0.2rem;
  border-radius: 0.625rem;
  cursor: pointer;
  font-size: 1.375rem;
  font-weight: 700;
  justify-self: center;
  align-self: center;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);
}

.tableItem {
  display: grid;
  grid-template-columns: 1fr;
  &.payroll {
    grid-template-columns: 3rem auto;
  }

  &.header {
    z-index: 2;

    &.payroll {
      grid-template-rows: auto auto;
      grid-template-columns: 100%;
    }

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

.tableItemMain {
  display: grid;
  background: var(--Gradient2);
  background-color: var(--BaseSiteColor2);

  &.header {
    position: sticky;
    top: 0;
    z-index: 1;

    &.options {
      padding-left: 4rem;
      justify-content: space-between;
      grid-auto-flow: column;
      min-height: 3.3rem;

      .itemValue {
        justify-content: center;
      }
    }
  }

  &.job {
    grid-template-columns: 1fr 10rem 10rem 16rem;
  }

  &.personnel {
    grid-template-columns: 1fr 25rem 25rem 10rem 8rem 8rem 10rem;

    &.payroll {
      grid-template-columns: 1fr 12rem;
    }

    &.payrollSubList {
      grid-template-columns: 1fr 1fr 10rem 8rem 8rem 12rem;
    }

    &.header {
      display: grid;
      background: var(--Gradient5);
      color: var(--SiteFontColor2);
      background-color: var(--BaseSiteColor2);

      &.task {
        grid-template-columns: 25rem 1fr 10rem 10rem 12rem;
      }

      &.personnel {
        grid-template-columns: 1fr 25rem 25rem 10rem 8rem 8rem 10rem;

        &.payroll {
          grid-template-columns: 3rem 1fr 12rem;
        }

        &.payrollSubList {
          grid-template-columns: 1fr 1fr 10rem 8rem 8rem 12rem;
        }
      }

      &.job {
        grid-template-columns: 1fr 10rem 10rem 16rem;
      }
    }
  }

  &.task {
    grid-template-columns: 25rem 1fr 10rem 10rem 12rem;
  }

  @media (max-width: 1268px) {
    &.header {
      &.options {
        grid-auto-flow: row;
        padding-right: 4rem;
      }
    }

    &.job {
      grid-template-columns: 1fr 1fr;
    }

    &.personnel {
      grid-template-columns: 1fr 1fr;

      &.payroll {
        grid-template-columns: 1fr 1fr;
      }

      &.payrollSubList {
        grid-template-columns: 1fr 1fr;
      }

      &.header {
        &.task {
          grid-template-columns: 1fr 1fr;
        }

        &.personnel {
          grid-template-columns: 1fr 1fr;

          &.payroll {
            grid-template-columns: 3rem 1fr 12rem;
          }

          &.payrollSubList {
            grid-template-columns: 1fr 1fr;
          }
        }

        &.job {
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    &.task {
      grid-template-columns: 1fr 1fr;
    }
  }

  &.odd {
    background-color: var(--BaseSiteColor4);
  }
}

.subListContainer {
  display: grid;
  justify-content: flex-start;
  align-content: flex-start;
  grid-template-columns: 1fr;
  height: min-content;
  width: 100%;
  grid-column-start: 2;

  .header {
    &.payrollSubList {
      z-index: 0;
    }
  }
}

.itemValue {
  &.reports {
    display: grid;
    font-size: 1.375rem;
    font-weight: 700;
    padding: 0.625rem;
    color: var(--SiteFontColor1);
    width: 100%;
    border: 0.1rem solid var(--BaseSiteColor1);
    text-align: left;

    &.options {
      border: 0;
      display: grid;
      grid-template-columns: max-content max-content;
      column-gap: 0.625rem;
    }

    &.expand {
      justify-content: center;
      align-content: center;
      background: var(--Gradient2);
      background-color: var(--BaseSiteColor2);
      cursor: pointer;
      &.odd {
        background-color: var(--BaseSiteColor4);
      }
    }

    &.overdue {
      color: var(--SiteFontColor3);
    }

    @media (max-width: 1268px) {
      &.name {
        grid-column: span 2;
      }
      &.date {
        grid-column: span 2;
      }
      &.lastColumn {
        grid-column: span 2;
        text-align: right;
      }
      &.expand {
        grid-row: 1 / 10;
        align-content: flex-start;
      }
    }
  }
}

.innerValue {
  &.reports {
    width: 14rem;
    height: 2rem;
    font-size: 1.25rem;
    grid-column: 2;
  }
}

.filteredModalItemsContainer {
  display: grid;
  grid-auto-rows: min-content;
  background-color: var(--BaseSiteColor2);
  width: 100%;
  height: 100%;
}

.filteredModalItem {
  display: grid;
  grid-template-columns: 10rem auto;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor2);
  min-height: 3rem;
  color: var(--SiteFontColor1);

  &:nth-child(odd) {
    background-color: var(--BaseSiteColor4);
  }
}

.filteredModalItemValue {
  font-size: 1.25rem;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 0.2rem 1rem;
  border: solid 0.1rem var(--BaseSiteColor1);
  @include m.flexCenter(column);
  row-gap: 0.5rem;
  padding: 0.5rem;

  select {
    width: 100%;
    color: var(--SiteFontColor2);
  }

  input::placeholder {
    color: var(--SiteFontColor1);
  }
}

.filteredModalItemLabel {
  font-size: 1.25rem;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 0.2rem 1rem;
  font-weight: 600;
  border: solid 0.1rem var(--BaseSiteColor1);
  @include m.flexCenter(column);
  row-gap: 0.5rem;
  + select {
    width: 100%;
    color: var(--SiteFontColor2);
  }
}

.filteredModalItemValueInner {
  height: 100%;
  width: 22rem;
  .foragerMultiSelect {
    .foragerMultiSelectAnchor {
      .foragerMultiSelectList {
        position: fixed;
        width: inherit;
        z-index: 5;
      }
    }
  }
}

.App .tox-tinymce {
  border-radius: 0.5rem;
  border: none;
  overflow: unset;

  .tox-editor-container {
    width: 100%;
    overflow: unset;

    .tox-view-wrap {
      background-color: transparent;
    }

    .tox-editor-header {
      position: sticky;
      top: 0px;
      background-color: var(--BaseSiteColor2);
      background-image: var(--Gradient1);
      border-bottom: rgba(var(--BaseSiteColor1-rgb), 0.5) solid 0.1rem;
      box-shadow: none;

      .tox-toolbar {
        background-color: transparent;
      }
      .tox-toolbar-overlord {
        background-color: transparent;
      }
      .tox-toolbar__primary {
        background-color: transparent;
        justify-content: space-between;
      }
    }

    .tox-edit-area__iframe {
      background-color: transparent;
    }
  }

  .tox-statusbar {
    background-color: var(--BaseSiteColor2);
    background-image: var(--Gradient2);
    border-color: rgba(var(--BaseSiteColor1-rgb), 0.5);
    position: sticky;
    bottom: 0px;
  }
}

.tox.tox-tinymce-aux {
  background-color: transparent;

  .tox-toolbar__overflow {
    background: var(--Gradient4) !important;
    background-color: var(--BaseSiteColor4) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
}

// Admin Item Customer Settings

.adminItemCustomerSettingsTableContainer {
  display: grid;
  grid-auto-rows: min-content;
  justify-content: flex-start;
  align-content: flex-start;
  border: var(--BaseSiteColor1) solid 0.2rem;
  background-color: var(--BaseSiteColor4);
  color: var(--BaseSiteColor1);
  height: fit-content;
  width: fit-content;
  min-width: 100%;

  &:nth-child(odd) {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.6);
  }
}

.adminModalLabel {
  font-size: 1.5rem;
  font-weight: 700;
}

.adminItemCustomerSettingsTableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.7875rem;
  font-weight: 500;
  font-size: 2rem;
  color: var(--BaseSiteColor1);
  border-bottom: var(--BaseSiteColor1) solid 0.2rem;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor4);
  width: 100%;
}

.adminItemCustomerSettingTableBodyContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  background-color: var(--BaseSiteColor4);
  width: 100%;
}
.adminItemCustomerSettingTableBodyItem {
  display: grid;
  justify-content: center;
  align-content: center;
  color: var(--BaseSiteColor1);
  padding: 0.5rem;
  height: min-content;
}

.adminItemCustomerSettingsTableHeaderItem {
  border-right: var(--BaseSiteColor1) solid 0.2rem;
}

.adminItemCustomerSettingTableBody {
  overflow-y: auto;
  scrollbar-gutter: stable;
  max-height: 10rem;
}

.adminItemCustomerSettingsItem {
  font-size: 2rem;
}

.pdfViewer {
  &.modalMain {
    top: 1rem;
    max-height: calc(100vh - 2rem);
    max-height: calc(100vh - 2rem);
    max-width: 95vw;
    max-width: 95dvw;
    display: grid;
    justify-content: center;
    align-content: center;
  }

  &.modalMainInner {
    height: 100%;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
}

.react-pdf__Document {
  overflow: hidden;
  display: grid;
  align-content: center;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}
.react-pdf__message--loading {
  display: grid;
  justify-content: center;
  align-content: center;
  justify-self: center;
  align-self: center;
  height: 100%;
  width: 100%;
}
.pdfViewerLogo {
  display: grid;
  align-self: center;
  justify-self: center;
  width: 10rem;
  height: auto;
}

.pdfViewerModal {
  padding: 0.5rem 1.7rem 0.5rem 2.6rem;
  height: 100%;
  max-height: 95vh;
  max-height: 95dvh;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  width: 50rem;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  color: var(--SiteFontColor1);
  justify-content: center;

  .pdfViewerDocument {
    display: grid;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }

  .pdfViewerPage {
    width: 100%;
    max-width: 100%;

    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }
  }

  .pdfViewerControlsContainer {
    position: relative;
    font-size: 1rem;
    padding: 0.5rem;
    text-align: center;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    column-gap: 1rem;
    background-color: rgba(var(--BaseSiteColor1-rgb), 1);
    color: var(--SiteFontColor2);
    opacity: 0.3;
    z-index: 1;

    &:hover {
      opacity: 1;
      .pdfViewerPageNumbers {
        color: var(--SiteFontColor2);
      }
    }

    .pdfViewerPageNumbers {
      width: fit-content;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;

      color: var(--SiteFontColor2);
      font-weight: 500;
    }

    & .pdfButtons {
      font-size: 1.5rem;
      border: 1px solid var(--SiteFontColor2);
      border-radius: 50%;
      padding: 0.1rem;
      display: grid;
      justify-items: center;
      align-items: center;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &:hover {
        font-weight: 700;
        outline: 1.5px solid var(--SiteFontColor2);
      }
    }

    .pdfViewerDownloadIcon {
      text-decoration: none;
      color: var(--SiteFontColor2);
      display: grid;
    }
  }

  .pdfViewerDownloadButton {
    font-size: 1.5rem;
    border: 1.5px solid var(--BaseSiteColor2);
    background-color: rgba(var(--BaseSiteColor2-rgb), 0.5);
    padding: 0.125rem 0.25rem;
    border-radius: 15%;
    display: grid;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
    cursor: pointer;
  }
}

.timeDashboardContainer {
  display: grid;
  grid-template-rows: min-content 1fr;
  color: var(--SiteFontColor1);
  font-weight: 700;
  height: 82vh;
  height: 82dvh;
  width: 95vw;
  background-color: var(--BaseSiteColor2);
  background-image: var(--Gradient3);

  .timeDashboardBody {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: calc(50% - 0.5rem) calc(50% - 0.5rem);
    grid-gap: 1rem;
    padding: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    background-color: var(--BaseSiteColor2);
    background-image: var(--Gradient3);

    @media (max-width: 1268px) {
      grid-template-rows: unset;
      grid-auto-rows: min-content;
      grid-template-columns: 100%;
    }

    .timeDashboardSection {
      display: grid;
      grid-template-rows: 1fr;
      border: 0.1rem solid var(--BaseSiteColor1);
      background-color: var(--BaseSiteColor1);
      border-radius: 0.5rem;
      height: min-content;
      max-height: 100%;
      overflow: hidden;

      @media (max-width: 1268px) {
        max-height: 30rem;
      }

      .timeDashboardSectionTitle {
        display: grid;
        height: 2rem;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 1.3rem;
        color: var(--SiteFontColor2);
        background-color: var(--BaseSiteColor1);
        background-image: var(--Gradient2);
      }

      .percentageBar {
        display: grid;
        justify-self: flex-start;

        height: 80%;
        width: 100%;
        border-radius: 0.5rem;
        border: 0.1rem solid var(--BaseSiteColor1);
        grid-column: 1;
        grid-row: 1;
        background-image: var(--Gradient2);
      }

      .percentageNumber {
        display: grid;
        grid-column: 1;
        grid-row: 1;
      }
    }
  }
}

@use "./Mixins" as m;

.modalContainer {
  position: fixed;
  top: 0;
  left: 7rem;
  width: calc(100vw - 7rem);
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1260px) {
    left: 0;
    width: 100vw;
    padding-left: 4rem;
  }
}

.modalMain {
  position: relative;
  max-height: calc(100vh - 7rem);
  max-height: calc(100dvh - 7rem);
  max-width: 100%;
  top: 6rem;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor4);
  border-radius: 0.625rem;
  z-index: 4;
  box-shadow: rgba(0, 0, 0, 0.6) 0 0.3rem 1.25rem 0;
  overflow: hidden;

  @media (min-width: 1269px) {
    &.fromTop {
      top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      // transition: all 5s ease-in-out;
    }
  }
}

.modalMainInner {
  position: relative;
  overflow: auto;
  padding: 1.25rem;

  &.noPadding {
    padding: 0;
  }
  &.noPaddingAlternate {
    padding: 0;
  }
}

.modalTitle {
  font-size: 1.2rem;
  font-weight: 600;
  width: 15rem;
}

.modalCloseButton {
  position: absolute;
  top: 0;
  right: 0.3rem;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  color: var(--SiteFontColor1);
  z-index: 2;
  cursor: pointer;

  &.noPaddingAlternate {
    color: var(--SiteFontColor2);
  }

  &.changeOnSmall {
    @media (max-width: 700px) {
      color: var(--SiteFontColor2);
      width: 4.5rem;
      height: 4.5rem;
      font-size: 2.5rem;
      padding: 1rem;
    }
  }
}

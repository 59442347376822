.documentManagement {
  display: grid;
  grid-auto-rows: 1fr;
  color: var(--SiteFontColor1);
  grid-row: span 2;

  &.dragging * {
    pointer-events: none;
  }
  height: 100%;
  width: 100%;
  overflow: hidden;

  .titleBar {
    width: 100%;
    height: 4rem;
    display: grid;
    background-color: var(--BaseSiteColor1);
    color: var(--SiteFontColor2);
    font-size: 2rem;
    font-weight: 600;
    justify-content: flex-start;
    align-content: center;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }
}

.DocumentDragOverOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
  border-radius: 0.625rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.3rem 0.625rem;
  min-height: 100%;
  font-size: 2rem;
  font-weight: 700;
  justify-content: center;
  align-content: center;
  color: var(--SiteFontColor2);

  &.active {
    display: grid;
    z-index: 100;
  }
}

.documentManagementAddBar {
  display: grid;
  grid-template-rows: min-content 1fr;
  overflow: hidden;
  height: 100%;
  .documentManagementTabs {
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    background-color: var(--BaseSiteColor2);
    padding: 0.5rem;
    padding-bottom: 0;
    border-bottom: solid var(--BaseSiteColor1) 0.1rem;

    &.single {
      grid-template-columns: 1fr;
    }

    .tab {
      display: grid;
      position: relative;
      top: 0.1rem;
      justify-content: center;
      align-content: center;
      align-items: center;
      justify-items: center;
      text-align: center;
      font-weight: 700;
      cursor: pointer;
      color: var(--SiteFontColor1);
      padding: 0.5rem 0;
      &.active {
        position: relative;
        top: 0.1rem;
        background-color: var(--SiteBackgroundColor2);
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        border: solid var(--BaseSiteColor1) 0.1rem;
        border-bottom: solid var(--SiteBackgroundColor2) 0.1rem;
      }
    }
  }
  .selectedDocumentsContainer {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: min-content;
    overflow: auto;
    max-height: 40vh;
    max-height: 40dvh;
    padding: 0.5rem;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    min-height: 10rem;
    border: solid var(--BaseSiteColor1) 0.1rem;
    border-right: none;
    border-left: none;

    &.noDocuments {
      display: grid;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-align: center;
      justify-self: center;
      align-self: center;
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--SiteFontColor1);
      opacity: 0.7;
    }
    .selectedDocument {
      display: grid;
      width: 100%;
      grid-template-columns: min-content 1fr;
      column-gap: 0.5rem;
      border-radius: 0.5rem;
      padding: 0.25rem;
      justify-content: center;
      align-content: center;
      text-align: left;

      &:hover {
        background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
        color: var(--SiteFontColor2);
        .removeDocumentIcon {
          opacity: 1;
        }
      }

      .removeDocumentIcon {
        opacity: 0;
        font-size: 1.1rem;
        cursor: pointer;
        color: var(--BaseSiteColor5);
        &:hover {
          color: var(--BaseSiteColor4);
        }
      }
      .selectedDocumentName {
        font-size: 1rem;
      }
    }
  }
}

.DocumentBodySection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 100%;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  @media (max-width: 1268px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    overflow: hidden;
  }
}

.DocumentsItem {
  background-color: rgba(var(--BaseSiteColor3-rgb), 0.3);
  color: var(--SiteFontColor1);
  display: grid;
  grid-template-columns: 1fr max-content max-content max-content max-content;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  gap: 0.5rem;

  &.withExtraButton {
    grid-template-columns: 1fr max-content max-content max-content max-content max-content;
  }

  &.clicked {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.6);
  }
  &:nth-child(odd) {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.4);
    &.clicked {
      background-color: rgba(var(--BaseSiteColor1-rgb), 0.6);
      color: var(--SiteFontColor2);
      &:hover {
        background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
      }
    }
  }

  &.deleting {
    background-color: rgba(var(--BaseSiteColor5-rgb), 0.5);
    color: var(--SiteFontColor1);
    &:nth-child(odd) {
      background-color: rgba(var(--BaseSiteColor5-rgb), 0.6);
      &.clicked {
        background-color: rgba(var(--BaseSiteColor5-rgb), 0.8);
        color: var(--SiteFontColor2);
        &:hover {
          background-color: rgba(var(--BaseSiteColor5-rgb), 0.8);
        }
      }
    }

    &:hover {
      background-color: rgba(var(--BaseSiteColor5-rgb), 0.8);
    }
  }

  &:hover {
    color: var(--SiteFontColor2);
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
  }
}

.DocumentItemSection {
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: grid;
  overflow: hidden;
  border-right: var(--BaseSiteColor1) solid 0.1rem;
  grid-template-rows: min-content 1fr;
  box-sizing: border-box;

  .searchbar {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0.5rem;
    padding: 0.5rem;
    justify-items: center;
    align-items: center;
    width: 100%;
    background-color: var(--BaseSiteColor2);
    border-bottom: var(--BaseSiteColor1) solid 0.1rem;
    input {
      height: 2.5rem;
      border-radius: 1.25rem;
      padding: 0.5rem 1rem;
      min-height: 2.5rem;
      font-size: 1rem;
      text-align: left;
      color: var(--SiteFontColor1);
      border: solid 0.1rem var(--BaseSiteColor1);
      box-sizing: border-box;
      width: 100%;
    }
  }

  @media (max-width: 1268px) {
    border-bottom-left-radius: 0.625rem;
  }
}

.DocumentPreviewSection {
  overflow: hidden;
  border-right: none;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  justify-items: center;
  align-items: start;
  pointer-events: none;
  overflow: hidden;
  height: 100%;
  width: 100%;

  img {
    overflow: clip;
    width: fit-content;
    height: fit-content;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
    align-self: center;
  }

  .react-pdf__message--loading {
    display: grid;
    justify-content: center;
    align-content: center;
    align-self: center;
  }

  @media (max-width: 1268px) {
    border-radius: 0;
    border: var(--BaseSiteColor1) solid 0.1rem;
    object-fit: contain;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      overflow: hidden;
    }
  }
}

.DocumentPreviewWrapper {
  pointer-events: none;
  display: grid;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  max-height: 100%;
  max-width: 100%;
  .react-pdf__Document {
    overflow: hidden;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    .react-pdf__Page {
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      overflow: hidden;
      pointer-events: none;
      .react-pdf__Page__canvas {
        height: 100%;
        width: unset !important;
      }
    }
  }
}

.DocumentFilesActions {
  height: 100%;
  overflow: hidden;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  row-gap: 0.3rem;

  & .disabled {
    cursor: pointer;
    pointer-events: none;
  }

  .topLevel {
    z-index: 2;
  }
}

.DocumentUploadLogoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-content: flex-end;
  align-items: center;
  justify-items: center;
  padding: 1rem;
  background-color: rgba(var(--BaseSiteColor1-rgb), 0.7);
  z-index: 1;

  .DocumentUploadLogo {
    width: 5rem;
    height: auto;
    object-fit: contain;
  }
}

.toggleShowKnowledgeBasesButton {
  position: absolute;
  bottom: 0;
  left: 0;
}

.KnowledgeBaseList {
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 4rem 1fr 6rem;
  overflow: auto;
  border: var(--BaseSiteColor1) solid 0.1rem;
  border-radius: 0.625rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  box-sizing: border-box;
  justify-items: center;
  position: relative;
  color: var(--SiteFontColor1);
}

.KnowledgeBaseListTitle {
  align-self: center;
  font-weight: 700;
  font-size: 1.5rem;
  width: 100%;
  border-bottom: 0.1rem solid var(--BaseSiteColor1);
  padding: 1rem;
  color: var(--SiteFontColor1);
}

.KnowledgeBaseListBody {
  display: grid;
  overflow: auto;
  width: 100%;
  grid-auto-rows: 4rem;
}

.KnowledgeBaseListItem {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: 100%;
  max-height: 100%;
  width: 100%;
  border-bottom: var(--BaseSiteColor1) solid 0.1rem;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: var(--SiteFontColor2);
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.8);
  }
  &.clicked {
    background-color: rgba(var(--BaseSiteColor1-rgb), 0.6);
  }
  &.deleting {
    background-color: rgba(var(--BaseSiteColor5-rgb), 0.5);
    color: var(--SiteFontColor1);
    &:nth-child(odd) {
      background-color: rgba(var(--BaseSiteColor5-rgb), 0.6);
      &.clicked {
        background-color: rgba(var(--BaseSiteColor5-rgb), 0.8);
        color: var(--SiteFontColor2);
        &:hover {
          background-color: rgba(var(--BaseSiteColor5-rgb), 0.8);
        }
      }
    }
  }
}

.KnowledgeBaseListFooter {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  border-top: var(--BaseSiteColor1) solid 0.1rem;
}

.AddKnowledgeBaseModalContainer {
  display: grid;
  width: 30vw;
  width: 30dvw;
}

.AddKnowledgeBaseModalHeader {
  text-align: left;
  font-size: 1.5625rem;
  font-weight: 700;
  color: var(--SiteFontColor2);
  padding: 0.9375rem;
  background-color: var(--BaseSiteColor1);
}

.AddKnowledgeBaseModalBody {
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  row-gap: 0.25rem;
  justify-items: center;
  align-items: center;
}

.AddKnowledgeBaseModalBodyItem {
  display: grid;
  grid-template-columns: 0.75fr 2fr;
  grid-template-rows: auto;
  gap: 1rem;
  font-weight: 700;
  color: var(--SiteFontColor1);
  font-size: 1.375rem;
  width: 100%;
  padding: 0.25rem 0.5rem;
  align-items: center;
  justify-items: flex-start;

  & input:not([type="checkbox"]),
  textarea {
    font-family: inherit;
    padding: 0.5rem;
    font-size: 1.375rem;
    color: var(--SiteFontColor2);
    background-color: var(--BaseSiteColor1);
    border: none;
    border-radius: 0.625rem;
    resize: none;
    &#knowledgeBaseName,
    &.knowledgeBaseDescription {
      font-weight: 700;
      width: 100%;
      box-sizing: border-box;
    }
  }

  &.descriptionTextArea {
    height: auto;
  }
}

.ChooseKnowledgeBaseContainer {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  word-wrap: nowrap;
  & label {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--SiteFontColor1);
  }
}

.ChooseKnowledgeBaseDropdown {
  font-size: 1.25rem;
  width: 20rem;
  height: 2rem;
  width: fit-content;
  justify-items: center;

  & .label {
    padding: 0.1rem 1rem;
  }

  & .currentlySelected {
    border-radius: 0.3rem;
  }
}

.hiddenElement {
  display: none;
}

.DocumentsItemPart,
.KnowledgeBaseListItemPart {
  position: relative;
  &.logo {
    width: 3em;
    height: 3em;
  }
  &.action {
    font-size: 1.5rem;
    cursor: pointer;

    .tooltip {
      position: absolute;
      font-size: 1rem;
      width: fit-content;
      max-width: max-content;
      white-space: nowrap;
      padding: 0.25rem 0.5rem;
      background-color: var(--BaseSiteColor2);
      top: 40%;
      transform: translateY(-40%);
      right: 115%;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.25s ease-in-out, visibility 0s ease-in-out 0.25s;
      pointer-events: none;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -0.5rem;
        border-width: 0.5rem;
        border-style: solid;
        border-color: transparent transparent transparent var(--BaseSiteColor2);
      }
    }
    &:hover {
      color: var(--BaseSiteColor5);
      .tooltip {
        visibility: visible;
        opacity: 1;
        color: var(--SiteFontColor1);
        transition-delay: 0s;
      }
    }
  }
}

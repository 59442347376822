@use "./Mixins" as m;

//Base Gantt Styles
.mainGanttScreen {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;
}

.ganttContainer {
  display: grid;
  grid-template-rows: min-content auto;
  height: 100%;
  width: auto;
  overflow: hidden;
  padding: 0 0.2rem 0.2rem 0.4rem;

  &.range1 {
    .dayContainer {
      width: 2.1875rem;
      font-size: 0.7rem;
    }

    .sizeGrid {
      grid-auto-columns: 2.1875rem;
    }
  }
  &.range2 {
    .dayContainer {
      width: 2.375rem;
      font-size: 0.8rem;
    }

    .sizeGrid {
      grid-auto-columns: 2.375rem;
    }
  }
  &.range3 {
    .dayContainer {
      width: 2.6875rem;
      font-size: 0.8958rem;
    }

    .sizeGrid {
      grid-auto-columns: 2.6875rem;
    }
  }
  &.range4 {
    .dayContainer {
      width: 2.8125rem;
    }

    .sizeGrid {
      grid-auto-columns: 2.8125rem;
    }
  }
  &.range5 {
    .dayContainer {
      width: 3.4375rem;
    }

    .sizeGrid {
      grid-auto-columns: 3.4375rem;
    }
  }
  &.range6 {
    .dayContainer {
      width: 4.375rem;
    }

    .sizeGrid {
      grid-auto-columns: 4.375rem;
    }
  }
  &.range7 {
    .dayContainer {
      width: 6.25rem;
    }

    .sizeGrid {
      grid-auto-columns: 6.25rem;
    }
  }
}

.ganttSortDivider {
  left: 0;
  display: grid;
  grid-template-columns: 23rem auto;
  align-items: center;
  height: fit-content;
  min-height: 2.5rem;
  width: 100%;
  background: var(--Gradient3);
  background-color: var(--GanttColor1);
  color: var(--SiteFontColor5);
  font-size: 1.1rem;
  font-weight: 600;
  border-bottom: 0.1rem solid var(--BaseSiteColor1);

  .ganttSortDividerLabelContainer {
    position: sticky;
    display: grid;
    grid-template-columns: min-content auto;
    height: 100%;
    width: 100%;

    .ganttSortDividerLabel {
      display: grid;
      justify-content: center;
      align-items: center;
      border-right: 0.1rem solid var(--BaseSiteColor1);
      padding: 0 1rem;
      height: 100%;
      width: 100%;
    }

    .ganttSortDividerValue {
      // position: sticky;
      display: grid;
      justify-content: flex-end;
      align-items: center;
      border-right: 0.1rem solid var(--BaseSiteColor1);
      height: 100%;
      width: 100%;
      padding-right: 1rem;
    }
  }

  .ganttSortDividerSpacer {
    // background: var(--Gradient3);
    // background-color: var(--BaseSiteColor2);
    height: 100%;
    width: 100%;

    &.odd {
      // background-color: var(--BaseSiteColor4);
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: min-content auto 0;
  }
}

.ganttFilterNoticeContainer {
  width: fit-content;
  max-width: 100%;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor2);
  border-radius: 0.625rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  color: var(--SiteFontColor1);
  font-size: 1.1rem;
  font-weight: 600;

  .filterNoticeItem {
    display: grid;
    justify-content: flex-start;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: 0 max-content 1fr;
    column-gap: 0.5rem;
    width: fit-content;
    max-width: 100%;
    // grid-column-start: 2;
    padding: 0 0.5rem;
    cursor: pointer;

    &.main {
      padding-left: 1rem;
    }

    &:hover {
      color: var(--BaseSiteColor5);

      .removeFilterX {
        opacity: 1;
      }
    }

    .removeFilterX {
      opacity: 0;
      position: relative;
      left: -0.4rem;
    }

    .filterNoticeItemLabel {
      display: grid;
      justify-content: flex-start;
      width: 100%;
    }
  }

  .ganttFilterNotice {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 100%;
    max-width: 100%;
    padding: 0.5rem;

    .filterNoticeLabel {
      display: grid;
      justify-content: flex-start;
      align-items: center;
      grid-auto-columns: max-content;
    }
  }
}

.ganttChartMainItemContainer {
  height: max-content;
  width: max-content;
  background-color: var(--BaseSiteColor1);
  display: grid;
  min-width: 100%;

  &.spacers {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    contain: layout;
    opacity: 0.5;
  }
}

//Chart Item Styles
.chartItemContainer {
  display: grid;
  grid-template-columns: 23rem auto;
  height: fit-content;
  min-height: 2rem;
  width: max-content;
  border-bottom: 0.1rem solid var(--Outline1);
  background-color: var(--BaseSiteColor1);
  contain: paint;

  &.header {
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 0.1rem solid var(--BaseSiteColor1);
    height: 2rem;

    &:hover {
      opacity: 1;
    }
  }

  &.spacer {
    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 700px) {
    height: 3rem;
  }

  @media (max-width: 500px) {
    grid-template-columns: 100%;
    width: 100%;
  }

  &:hover {
    opacity: 0.75;

    .dayLine {
      display: grid;
      &.fullLine {
        height: 0.9rem;
        border-radius: 0.55rem;
        border-width: 0.2rem;
      }

      &.barStartsBefore {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.barEndsAfter {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .chartItemLabel {
      font-weight: 750;
    }
  }

  &.divider {
    left: 0;
    display: grid;
    grid-template-columns: 23rem auto;
    align-items: center;
    width: 100%;
    background: var(--Gradient3);
    background-color: var(--GanttColor1);
    color: var(--SiteFontColor5);
    font-size: 1.1rem;
    font-weight: 600;
    position: sticky;
    top: 2rem;
    border-bottom: 0.1rem solid var(--BaseSiteColor1);
    z-index: 1;
  }

  .dividerLabel {
    display: grid;
    justify-content: center;
    align-items: center;
    border-right: 0.1rem solid var(--BaseSiteColor1);
    padding: 0 1rem;
    height: 100%;
    width: 100%;
  }

  .dividerValue {
    display: grid;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-right: 1rem;
  }

  .dividerSpacer {
    height: 100%;
    width: 100%;
  }
}

.chartItemLabelContainer {
  position: sticky;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  left: 0;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor2);
  border-right: 0.1rem solid var(--BaseSiteColor1);
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  &.odd {
    background-color: var(--BaseSiteColor4);
  }

  &.header {
    display: grid;
    grid-auto-rows: max-content;
    justify-content: center;
    align-content: center;
    top: 0;
    z-index: 1;
    cursor: default;
  }

  &.divider {
    // justify-content: flex-end;
    background: var(--Gradient3);
    background-color: var(--GanttColor1);
  }
}

.chartItemLabel {
  @include m.flexCenter(row);
  padding: 0 0.75rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--SiteFontColor1);

  &.divider {
    font-size: 1.1rem;
    color: var(--SiteFontColor5);
  }
}

.chartItemDaysContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  justify-content: flex-start;
  width: max-content;
  height: 100%;
  position: inherit;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor2);
  padding: 0;

  &.odd {
    background-color: var(--BaseSiteColor4);
  }

  @media (max-width: 500px) {
    display: none;
  }
}

.dayContainer {
  display: grid;
  align-content: center;
  width: 2.8125rem;
  height: 100%;
  border-right: 0.1rem solid var(--Outline1);
}

.dayHeader {
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor3);
  color: var(--SiteFontColor5);
  font-size: 0.9375rem;
  font-weight: 700;

  &.dayWeekend {
    opacity: 0.8;
  }
}

.dayTodayHeader {
  background-color: rgba(var(--BaseSiteColor1-rgb), 0.55);
  color: var(--SiteFontColor2);
  font-size: 0.9375rem;
  font-weight: 700;
}

.dayToday {
  background-color: rgba(var(--BaseSiteColor1-rgb), 0.2);
}

.dayLine {
  background-color: var(--GanttColor2);
  display: grid;
  width: 100%;

  &.fullLine {
    border-radius: 0.4rem;
    align-self: center;
    border: 0.1rem solid var(--Outline1);
    height: 0.75rem;

    &.barStartsBefore {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }

    &.barEndsAfter {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      .changeStartOnChart {
        display: none;
      }
      .changeEndOnChart {
        display: none;
      }
    }

    &.variant1 {
      background-color: var(--BaseSiteColor3);
    }

    &.variant2 {
      background-color: var(--GanttColor2);
    }

    &.variant3 {
      background-color: var(--GanttColor3);
    }

    &.variant4 {
      background-color: var(--GanttColor4);
    }

    &.variant5 {
      background-color: var(--GanttColor5);
    }
  }

  &.lineContainer {
    background-color: unset;
    padding: 0 0.2rem;
    align-self: center;

    &.barStartsBefore {
      padding-left: 0;
    }

    &.barEndsAfter {
      padding-right: 0;
    }
  }

  &.startPoint {
    background-color: unset;
    height: 0;
    width: 0;
    grid-row: 1;
    align-self: center;
  }

  &.sizeGrid {
    background-color: unset;
    position: absolute;
    grid-auto-flow: column;
    grid-auto-columns: 2.8125rem;
    width: min-content;
    align-self: center;
  }

  &.lineStart {
    border-left: solid 0.1rem var(--GanttColor2);
    border-radius: 0.3rem 0 0 0.3rem;
    margin-left: 0.1rem;
  }

  &.lineEnd {
    border-right: solid 0.1rem var(--GanttColor2);
    border-radius: 0 0.3rem 0.3rem 0;
    margin-right: 0.1rem;
  }

  &.lineSingle {
    border-right: solid 0.1rem var(--GanttColor2);
    border-radius: 0.3rem;
    margin-right: 0.1rem;
  }

  .changeStartOnChart {
    width: 1.25rem;
    height: 175%;
    position: absolute;
    left: 0;
    align-self: center;
    cursor: ew-resize;
  }

  .changeEndOnChart {
    width: 1.25rem;
    height: 175%;
    position: absolute;
    right: 0;
    align-self: center;
    cursor: ew-resize;
  }
}

.GanttColor8 {
  background-color: var(--GanttColor6) !important;
  .chartItemLabel {
    color: var(--SiteFontColor5);
  }
}

.GanttColor9 {
  background-color: var(--GanttColor7) !important;
  .chartItemLabel {
    color: var(--SiteFontColor5);
  }
}

//Gantt Header Styles

.ganttHeaderContainer {
  display: grid;
  padding: 0.2rem 0;
  grid-template-columns: 23rem 1fr 23rem;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
    padding: 0.5rem 0;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;

    .rangeContainer {
      display: none;
    }

    .ganttFilterNoticeContainer {
      justify-self: center;
    }
  }
}

.ganttHeaderColumnTitle {
  @include m.flexCenter(column);
  justify-content: flex-end;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--SiteFontColor1);
  cursor: default;

  @media (max-width: 700px) {
    &:nth-child(1) {
      grid-row: span 2;
    }
  }
}

.ganttHeaderColumnSort {
  display: grid;
  grid-template-columns: 12rem min-content min-content;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  column-gap: 1rem;

  .sortByDropdown {
    width: 100%;

    .currentlySelected {
      border-radius: 0.75rem;
    }
  }

  .sortByArrow {
    display: grid;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--SiteFontColor1);
  }
}

//Gantt Chart Styles
.ganttChartContainer {
  box-sizing: border-box;
  height: 100%;
  width: auto;
  margin: 0;
  border: 0.15rem solid var(--Outline1);
  border-radius: 0.625rem;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor4);
  overflow: auto;
  scrollbar-gutter: stable;
  position: relative;
  transform: translateZ(0);
  grid-row: 2;
  grid-column: 1;
}

body ::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0);
  width: 0.9rem;
  height: 1rem;
}

body ::-webkit-scrollbar-thumb {
  background-color: var(--BaseSiteColor2);
  border: 0.2rem solid rgba(var(--BaseSiteColor1-rgb), 0.7);
  border-radius: 0.5rem;
  background-clip: content-box;
  min-height: min(100%, 4rem);
}

body ::-webkit-scrollbar-corner {
  display: none;
}

body ::-webkit-scrollbar-track {
  background: transparent;
  background-clip: content-box;
}

.ganttChartHeaderContainer {
  display: grid;
  grid-template-columns: 18.75rem auto;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  border-bottom: 0.1rem solid var(--BaseSiteColor1);
  width: max-content;
  height: 1.875rem;
  background-color: white;
  z-index: 1;
}

.ganttChartAddButtonContainer {
  @include m.flexCenter(row);
  position: sticky;
  height: 100%;
  width: 18.75rem;
  top: 0;
  left: 0;
  border-right: 0.1rem solid var(--BaseSiteColor1);
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor4);
  cursor: pointer;
  z-index: 1;
}

.ganttChartAddIcon {
  font-size: 0.625rem;
  color: var(--SiteFontColor2);
  margin-right: 0.2rem;
}

.ganttChartAddButton {
  @include m.flexCenter(row);
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--SiteFontColor2);
  border-radius: 0.9375rem;
}

.ganttLoadingScreen {
  visibility: hidden;
  @include m.flexCenter(column);
  height: 100%;
  width: 100%;
  color: var(--SiteFontColor2);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  grid-row: 2;
  grid-column: 1;
}

//Range Styles
.rangeContainer {
  display: grid;
  grid-template-columns: min-content auto;
  justify-content: center;
  align-items: center;
  width: min-content;
  column-gap: 1rem;
  width: 100%;

  @media (max-width: 700px) {
    justify-self: center;
    column-gap: 0;
  }
}

.rangeLabel {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--SiteFontColor1);
  text-align: center;
  width: 100%;

  @media (max-width: 700px) {
    display: none;
  }
}

.rangePartContainer {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  column-gap: 0.2rem;

  input {
    margin: 0;
  }
}

.rangeSlider {
  appearance: none;
  -webkit-appearance: none;
  width: 8rem;
  height: 1.1rem;
  border-radius: 0.3rem;
  background: rgba(var(--BaseSiteColor3-rgb), 0.3);
  outline: solid 0.1rem var(--BaseSiteColor1);
  opacity: 0.9;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.rangeSlider:hover {
  opacity: 1;
}

.rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  background: var(--BaseSiteColor1);
  cursor: pointer;
}

.rangeSlider::-moz-range-thumb {
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  background: var(--BaseSiteColor1);
  cursor: pointer;
}

.rangeButton {
  color: var(--SiteFontColor2);
  width: 1.2rem;
  height: 1.2rem;
  font-size: 1.2rem;
  padding-top: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: var(--SiteFontColor1);
    font-size: 1rem;
  }
}

.logoSVG {
  height: 100%;
  width: 100%;

  .logoMainColor {
    fill: var(--SiteLogoColor2);
  }

  .outerCircle {
    fill: var(--SiteLogoColor2);
  }
  .innerCircle {
    fill: var(--SiteLogoColor1);
  }
  .outerWedge {
    fill: var(--SiteLogoColor3);
  }
  .innerWedge {
    fill: var(--SiteLogoColor2);
  }
  .iqLogoMainColor {
    fill: var(--SiteLogoColor2);
  }
  .iqLogoSecondaryColor {
    fill: var(--SiteFontColor2);
  }
  .iqLogoTertiaryColor {
    fill: var(--SiteLogoColor1);
  }
  &.icon {
    filter: drop-shadow(0 0 0.2rem var(--BaseSiteColor1));
  }
  .gptLogoMainColor {
    fill: var(--SiteBackgroundColor2);
  }

  &.alternate {
    .logoMainColor {
      fill: var(--SiteLogoColor1);
    }

    .outerCircle {
      fill: var(--SiteLogoColor1);
    }
    .innerCircle {
      fill: var(--SiteLogoColor2);
    }
    .outerWedge {
      fill: var(--SiteLogoColor3);
    }
    .innerWedge {
      fill: var(--SiteLogoColor1);
    }
    .iqLogoMainColor {
      fill: var(--SiteLogoColor1);
    }
    .iqLogoSecondaryColor {
      fill: var(--SiteFontColor2);
    }
    .iqLogoTertiaryColor {
      fill: var(--SiteLogoColor1);
    }
    &.icon {
      filter: drop-shadow(0 0 0.2rem var(--BaseSiteColor1));
    }
    .gptLogoMainColor {
      fill: var(--SiteBackgroundColor2);
    }
  }
}

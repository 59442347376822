@use "./Mixins" as m;

.absolutePositionAnchor {
  height: 0;
  width: 0;
}

.mainScreenContainer {
  display: grid;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  grid-template-columns: 7rem 1fr;
  grid-template-rows: minmax(5rem, max-content) 1fr;
  overflow-x: hidden;
  user-select: none;

  @media (max-width: 1260px) {
    grid-template-columns: min-content 1fr;
  }
}

.mainScreenSideContainer {
  height: 100%;
  width: 100%;
  display: grid;
  position: relative;
  z-index: 10;
}

.mainScreenSidebarContainer {
  background-color: rgba(var(--BaseSiteColor3-rgb), 0.3);
  border-right: solid 0.1rem var(--BaseSiteColor1);
  height: 100%;
  width: 100%;
  min-width: 4rem;
  border-right: 0.1rem solid var(--BaseSiteColor1);
  position: relative;
  z-index: 1;
  display: grid;
  justify-content: center;
  align-items: center;

  &.header {
    display: none;

    @media (max-width: 1260px) {
      display: grid;
      float: left;
      height: 100%;
      width: 5.625rem;
      grid-row: span 2;
      z-index: unset;
      border-bottom: 0.2rem solid var(--BaseSiteColor2);
      border-right: 0.2rem solid var(--BaseSiteColor2);
      box-shadow: #000000 0 0 0.9375rem 0.15rem;
    }

    @media (max-width: 700px) {
      grid-row: span 3;
    }
  }
}

.mainScreenIcon {
  height: 3.125rem;
  width: 3.125rem;
  cursor: pointer;
}

.mainScreenContent {
  background: var(--Gradient5);
  background-color: var(--SiteBackgroundColor2);
  display: grid;
  grid-template-rows: min-content min-content auto;
  overflow: hidden;
  position: relative;
  z-index: 2;
  &.IQMainScreenContent {
    grid-template-rows: min-content auto;
  }
}

.mainScreenContentHeader {
  background: var(--Gradient3);
  background-color: var(--BaseSiteColor1);
  width: 100%;
  display: grid;
  grid-template-columns: 23rem 1fr 23rem;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
  overflow: hidden;

  @media (max-width: 1260px) {
    grid-template-columns: 23rem 1fr;
    justify-content: stretch;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    padding-right: 0;
  }
}

.mainScreenContentHeaderTitle {
  display: grid;
  grid-auto-flow: row;
  color: var(--SiteFontColor1);
  align-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 2.1875rem;
  cursor: default;
  width: 100%;

  .impersonationIndicator {
    color: var(--BaseSiteColor5);
    word-wrap: break-word;
    overflow-wrap: anywhere;
    font-size: 0.9rem;
  }

  @media (max-width: 1260px) {
    order: 3;
  }

  @media (max-width: 700px) {
    order: 2;
  }
}

.mainScreenContentHeaderClockContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100%;

  .foragerButton {
    justify-self: flex-start;
  }

  @media (max-width: 1260px) {
    grid-row: span 2;
    order: 2;
    grid-template-columns: max-content auto max-content;
    justify-content: flex-start;
    justify-self: flex-end;
    padding-right: 0.5rem;
    column-gap: 1rem;
  }

  @media (max-width: 700px) {
    order: 3;
    grid-row: span 2;
    // padding-right: 0;
    padding-bottom: 0.5rem;
    justify-content: center;
    justify-self: flex-end;
    grid-template-columns: 0 auto max-content;
  }

  .foragerButton {
    justify-self: flex-start;
  }
}

.mainScreenContentHeaderClockedInText {
  height: fit-content;
  // width: max-content;
  color: var(--SiteFontColor2);
  font-weight: 700;
  font-size: 1.25rem;
  padding: 0 0.625rem;
  cursor: default;

  &.boxShader {
    color: var(--SiteFontColor1);
  }
}

.mainScreenContentHeaderIconContainer {
  height: 4.375rem;
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  overflow: hidden;
  align-items: center;

  .mainScreenIcon {
    padding-top: 0.6rem;
    padding-right: 0.4rem;
    height: 4rem;
    justify-self: flex-end;
    align-self: flex-start;
    @media (max-width: 1260px) {
      display: none;
    }
  }

  .mainLogo {
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    max-width: 17rem;
    max-height: 3rem;

    #IQFullLogo {
      height: 4rem;
      align-self: center;
      justify-self: center;
    }
  }
  @media (max-width: 1260px) {
    order: 1;
    padding-top: 0.5rem;
  }
  @media (max-width: 700px) {
    justify-content: center;
  }
}

.mainScreenContentHeaderIcon {
  position: relative;
  height: 5.625rem;
  top: -0.6875rem;
  width: 100%;
}

.mainScreenContentBody {
  height: auto;
  width: auto;
  // background-image: var(--Gradient4);
  // background-color: var(--SiteBackgroundColor2);
  box-shadow: rgba(0, 0, 0, 0.3) 0 0.3rem 0.3rem 0;
  border-radius: 0.625rem;
  overflow: hidden;

  &.errorScreen {
    grid-column: 2;
    display: grid;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 100%;
  }
}

.showOnParentHover {
  position: absolute;
  display: none;
}

.foragerDropdown {
  background: var(--Gradient4);
  background-color: var(--BaseSiteColor1);
  border: outset;
  border-color: var(--BaseSiteColor1);
  border-radius: 0.625rem;
}

.mainScreenContentFilterRow {
  display: grid;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
  position: relative;
}

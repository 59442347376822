@mixin fullSize{
    width: 100%;
    height: 100%;
}

@mixin flexCenter($direction){
    display: flex;
    flex-direction: $direction;
    justify-content: center;
    align-items: center;
}

@mixin flex($direction, $justify, $align ){
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}
html {
  /* font-size: 14px; */
  font-size: min(max(1.75vmin, 10px), 14px);
}

.App div,
input {
  box-sizing: border-box;
}

.App input {
  font-family: inherit;
}

input::-ms-reveal {
  display: none;
}

a {
  color: var(--SiteFontColor1);
}

.App {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
  text-align: center;
  background-color: var(--SiteBackgroundColor2);
  /* font-size: min(calc(8px + 1vmin), 16px); */
}

@media (max-width: 700px) {
  .hideOnMobile {
    display: none;
  }
}

/* .App img {
  height: 300px;
} */

/* .App-logo {
  height: 40px;
  pointer-events: none;
} */
